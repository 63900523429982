import { MenuItem } from "primereact/menuitem";
import { NavigateFunction } from "react-router-dom";
import { IdwalInspection } from "../types/IdwalInspection";
import {AUTH_URL_PROTOCOL} from "../cognito/authConfig";

export const setupSideBar = (
    navigate: NavigateFunction,
    data: IdwalInspection[]
): MenuItem[] => {
    console.info(`https://${process.env.REACT_APP_PRESALE_DOMAIN}.idwalmarine.com`)
    const menuItems = [
        {
            label: "Pre-sale Dashboard",
            icon: "pi pi-fw pi-th-large",
            command: () => navigate("/"),
            url: `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_PRESALE_DOMAIN}.idwalmarine.com`
        }
    ] as MenuItem[];
    data.forEach((inspection) => {
        menuItems.push({
            label: inspection.name.toUpperCase(),
            icon: "pi pi-fw pi-file",
            items: [
                {
                    label: "Manage sharing options",
                    icon: "pi pi-fw pi-share-alt",
                    url: `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_PRESALE_DOMAIN}.idwalmarine.com/manage-sharing/${inspection.inspectionId}`
                },
                {
                    label: "View analytics",
                    icon: "pi pi-fw pi-chart-line",
                    url: `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_PRESALE_DOMAIN}.idwalmarine.com/analytics/${inspection.inspectionId}`
                },
                {
                    label: "Preview Report",
                    icon: "pi pi-fw pi-eye",
                    url: `/inspections/${inspection.inspectionId}/seller`
                }
            ]
        });
    });

    return menuItems;
};
