import React, {useEffect, useMemo, useState} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { HeaderBar, IdwalSideBar } from "@idwal/idwal-react-components";
import { AUTH_URL_PROTOCOL } from "./cognito/authConfig";
import { MenuItem } from "primereact/menuitem";
import ReportAccess from "./pages/ReportAccess";
import RequestReportAccess from "./pages/RequestReportAccess";
import Footer from "./components/footer";
import { ConsentCookie } from "./components/consent-cookie/ConsentCookie";
import { Slide, ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { setupSideBar } from "./helpers/setupSidebar";
import { getInspections } from "./services/Inspection";
import { getCurrentSessionIdToken } from "./cognito/cognitoCookie";
import { IdwalInspection } from "./types/IdwalInspection";
import Seller from "./pages/Seller";
import { getSellerAccessToken } from "./services/AccessService";
import { setAccessTokenCookie } from "./helpers/cookieCreator";
import { COOKIE_NAME } from "./constants/Cookie";
import Payments from "./pages/Payments";
import { PaymentContextProvider } from "./components/payment/PaymentContext";
import { hotjar } from "react-hotjar";
import HotJarConfig from "./config/hotjar";
import {AppContextType} from "./types/AppContextType";

const queryClient = new QueryClient();

let hotjarInitialised = false;

export const AppContext = React.createContext<AppContextType | null>(null);

function App() {
    const [sellerToken, setSellerToken] = useState<string>("");

    const [idwalCookieIsValid, setIdwalCookieIsValid] = useState(false);
    const [sideBarItems, setSideBarItems] = useState<MenuItem[] | null>(null);

    const isSeller = sellerToken != "";

    const [sideBarOpen, setSideBarOpen] = useState(isSeller);

    const [restrictedView, setRestrictedView] = useState<boolean>(false);


    const navigate = useNavigate();

    const onAuthenticationChange = (isValid: boolean) => {
        setIdwalCookieIsValid(isValid);
    };

    if (!hotjarInitialised) {
        if (HotJarConfig.trackingId && HotJarConfig.trackingId !== 0) {
            hotjar.initialize(HotJarConfig.trackingId, HotJarConfig.version);
        } else {
            console.info("HotJar analytics not configured");
        }

        hotjarInitialised = true;
    }

    useEffect(() => {
        async function getData() {
            const sellerCognitoToken: string = await getCurrentSessionIdToken();
            const sellerAccessToken = await getSellerAccessToken(
                sellerCognitoToken
            );

            if (sellerAccessToken && sellerAccessToken.accessToken) {
                setAccessTokenCookie(
                    COOKIE_NAME,
                    sellerAccessToken.accessToken
                );
                localStorage.setItem(
                    "accessToken",
                    sellerAccessToken.accessToken
                );
                loadSidebar(sellerCognitoToken);
                onAuthenticationChange(true);
            }

            setSellerToken(sellerCognitoToken);
        }
        getData();
    }, []);

    async function loadSidebar(token: string) {
        const sellerInspections: IdwalInspection[] = await getInspections(
            token
        );
        const sideBarMenuItems = setupSideBar(navigate, sellerInspections);
        setSideBarItems(sideBarMenuItems);
    }

    useEffect(() => {
        loadSidebar(sellerToken);
    }, [sellerToken]);

    const providerValues = useMemo(() => ({ restrictedView  , setRestrictedView }),
        [restrictedView]); // value is cached by useMemo


        return (
            <AppContext.Provider value={providerValues}>
                <QueryClientProvider client={queryClient}>
                    <div className="flex flex-column min-h-screen">
                        <HeaderBar
                            burgerMenu={isSeller}
                            onClick={() => setSideBarOpen(true)}
                            logo="presale"
                        />
                        <IdwalSideBar
                            sideBarItems={
                                [
                                    {
                                        title: "PRE-SALE",
                                        items: sideBarItems
                                    }
                                ]
                            }
                            customerPortalLink={`${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_CUSTOMER_PORTAL_URL}/customer/inspection?sortOrder=log_date_desc`}
                            logoutLink={`${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_AUTH_URL}/logout?redirect=https://${process.env.REACT_APP_PRESALE_DOMAIN}.idwalmarine.com`}
                            visible={sideBarOpen}
                            onClose={() => {
                                setSideBarOpen(false);
                            }}
                        />
                        <Routes>
                            <Route
                                path="/inspections/:inspectionId/access"
                                element={
                                    <ReportAccess
                                        onAuthenticationChange={onAuthenticationChange}
                                    />
                                }
                            />
                            <Route
                                path="/inspections/:inspectionId/seller"
                                element={
                                    <Seller idwalCookieIsValid={idwalCookieIsValid}/>
                                }
                            />
                            <Route
                                path="/inspections/:inspectionId/access/request"
                                element={<RequestReportAccess/>}
                            />
                            <Route
                                path="/inspections/:inspectionId/access/payment"
                                element={
                                    <PaymentContextProvider>
                                        <Payments/>
                                    </PaymentContextProvider>
                                }
                            />
                        </Routes>
                        <div id="adobe-dc-view"></div>
                        ;
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={true}
                            closeOnClick
                            pauseOnHover
                            theme="colored"
                            transition={Slide}
                        />
                        <Footer idwalCookieIsValid={idwalCookieIsValid}/>
                        <ConsentCookie/>
                    </div>
                </QueryClientProvider>
            </AppContext.Provider>

        );
}

export default App;
