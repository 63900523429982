import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { Trans, useTranslation } from "react-i18next";

/**
 * Post Stripe Payment failure page
 * @returns
 */
export const PaymentFailure = () => {
    const { t } = useTranslation("locale");

    return (
        <Card title={<Trans i18nKey="payments.errors.title" t={t}></Trans>}>
            <div>
                <p className="mt-2">
                    <Trans i18nKey="payments.errors.message" t={t}>
                        We are looking into the problem and our systems will be
                        back online soon.
                    </Trans>
                </p>

                <p className="mt-3">
                    <Trans i18nKey="payments.errors.message_email" t={t}>
                        Please contact <a href="mailto:enquiries@idwalmarine.com"> enquiries@idwalmarine.com</a>for help with your enquiry.
                    </Trans>
                </p>
            </div>
        </Card>
    );
};
