import axios from "axios";
import { IdwalInspection } from "../types/IdwalInspection";

const RESULTS_SIZE = 5;

export const getInspections = async (jwtToken: string): Promise<IdwalInspection[]> => {

    const apiBaseUrl = process.env.REACT_APP_IDWAL_API_URL;
    const api = axios.create({
        baseURL: apiBaseUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`
        }
    });
    
    if(!jwtToken){
        return [];
    }

    const inspections: IdwalInspection[] = [];

    return api
        .get(`/Inspections?page_size=${RESULTS_SIZE}`)
        .then((response) => {
            for (const inspectionData of response.data) {
                inspections.push({
                    name: `${inspectionData.shipName}`,
                    imo: `${inspectionData.imoNumber}`,
                    id: `${inspectionData.inspectionNumber}`,
                    fromDate: `${inspectionData.dateFrom}`,
                    toDate: `${inspectionData.dateTo}`,
                    location: `${inspectionData.location}`,
                    imageUrl: `${inspectionData.shipImageUrl}`,
                    reportDate: `${inspectionData.reportDate}`,
                    inspectionId: `${inspectionData.inspectionId}`,
                    grade: inspectionData.idwalGrade
                });
            }

            return inspections.sort((a, b) =>
                b.reportDate.localeCompare(a.reportDate)
            );
        })
        .catch(err => {
            console.error(err);
            return [];
        });
};
