import { TFunction, Trans } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default  function UnpaidCustomer(t: TFunction, shipName: string): MessagesMessage {
    return {
        sticky: true,
        severity: "info",
        closable: false,
        detail: (
            <p className="errorText">
                <Trans i18nKey="toasts.unpaidCustomer" t={t}>
                    To access the pre-sale report for the {{shipName}} we need to collect payment.
                    Please call us on <strong>+44 2920 446 644</strong> or <a
                    className="contactUsUnpaidText"
                    href="https://www.idwalmarine.com/contact-us"
                    target="_blank">contact us</a> via our website and we will respond to you as soon as possible.
                </Trans>
            </p>
        )
    }
}
