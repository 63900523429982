import { Galleria, GalleriaItemChangeEvent } from "primereact/galleria";
import { ReactElement, useState } from "react";
import { useParams } from "react-router-dom";
import { getPhotos } from "../../queries/photosQueries";
import { showRequestErrorToast } from "../../services/ErrorService";
import { PhotoData } from "../../types/PhotoData";
import { LoadingShipImage } from "../loading/LoadingShipImage";
import { GA_BUTTON_EVENT, GA_IMAGE_CAROUSEL } from "../../constants/GaEvents";
import { sendGAEvent } from "../../services/AnalyticsService";
import {ImageAlbumGalleryComponent} from "./ImageAlbumGallery";
import {getDownloads} from "../../queries/downloadQueries";
import {GetPhotosResponse} from "../../types/responses/GetPhotosResponse";

export const VesselGallery = (): ReactElement => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const { inspectionId } = useParams();
    if (!inspectionId) {
        console.error("No inspection id.");
        showRequestErrorToast("Vessel Information");
        return <div></div>;
    }

    const { data, isLoading, isError, error } = getPhotos(inspectionId);
    const { data: documentData } = getDownloads(inspectionId ?? "");


    const photoTemplate = (item: PhotoData) => {
        return <img src={`${item}`} className="block w-full" />;
    };

    const placeHolderImage = {
        filename: "ship-image-placeholder.png",
        url: "/ship-image-placeholder.png",
        isMain: true,
        elementName: "Vessel Placeholder Image"
    } as PhotoData;

    if (isLoading || !data) {
        return <LoadingShipImage />;
    }

    if (isError) {
        console.error(error);
        showRequestErrorToast("Vessel photos");
        return <div></div>;
    }

    const mainPhotoAlbum: GetPhotosResponse = data?.filter((album) => album.isDefault)[0];

    mainPhotoAlbum.images?.unshift(mainPhotoAlbum.frontImage);
    const mainPhotos = [...new Set(mainPhotoAlbum.images)];
    const multipleImages = mainPhotos && mainPhotos?.length > 1;

    const galleryComponent = () => {
        return ImageAlbumGalleryComponent(data, documentData, undefined, "absolute bottom-0 mr-2 mb-2 right-0 z-5");
    }

    if (!multipleImages) {
        return (
            <div className={"relative"}>
                {galleryComponent()}
                <img
                    src={`${mainPhotos?.[0] ?? placeHolderImage.url}`}
                    className="block w-full"
                />
            </div>

        );
    }

    const handleNextImage = (e: GalleriaItemChangeEvent) => {
        setActiveIndex(e.index);
        sendGAEvent(GA_BUTTON_EVENT, GA_IMAGE_CAROUSEL);
    };

    return (
        <div className={"relative"}>
            {galleryComponent()}
            <Galleria
                value={mainPhotos}
                numVisible={5}
                circular
                style={{ maxWidth: "1024px" }}
                showItemNavigators
                showItemNavigatorsOnHover
                showThumbnails={false}
                activeIndex={activeIndex}
                onItemChange={(e) => handleNextImage(e)}
                item={photoTemplate}
            />
        </div>

    );
};
