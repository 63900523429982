import { ReactElement } from "react";
import { showRequestErrorToast } from "../services/ErrorService";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Loading } from "./loading/Loading";
import { getNotableItems } from "../queries/notableItemQueries";
import { NotableItems as NotableItemsTable } from "@idwal/idwal-react-components";
import { sendGAEvent } from "../services/AnalyticsService";
import { GA_BUTTON_EVENT } from "../constants/GaEvents";
import { NotableItem } from "../types/responses/gradingResponse";

export const NotableItems = (): ReactElement => {
    const { t } = useTranslation("locale");
    const { inspectionId } = useParams();

    if (!inspectionId) {
        console.error("No inspection id.");
        showRequestErrorToast("Notable Items");
        return <div />;
    }

    const { data, isError, error, isLoading } = getNotableItems(inspectionId);

    if (isLoading || data == undefined) return <Loading />;

    if (isError) {
        console.error(error);
        showRequestErrorToast("Notable Items");
        return <div />;
    }

    if (data.length === 0) {
        return <div>{t("defects.noNotableItems")}</div>;
    }

    const handleAnalyticsEvent = (action: string) => {
        sendGAEvent(GA_BUTTON_EVENT, action);
    }

    return (
        <NotableItemsTable 
            notableItems={data as Array<NotableItem>} 
            sendAnalyticsEvent={handleAnalyticsEvent} 
            showImageGrid={false} 
        />
    );
};
