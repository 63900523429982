import { ReportDetails } from "@idwal/idwal-react-components";
import { ReactElement } from "react";
import { LoadingParticulars } from "../loading/LoadingParticulars";
import { useParams } from "react-router-dom";
import { showRequestErrorToast } from "../../services/ErrorService";
import { useQuery } from "react-query";
import DataRequest from "../../queries/DataRequest";
import { VesselParticularsResponse } from "../../types/responses/VesselParticularsResponse";

export const VesselParticulars = (): ReactElement => {
    const { inspectionId } = useParams();

    const { data, isError, isLoading, error } =
        useQuery<VesselParticularsResponse>(["particulars"], () =>
            DataRequest(inspectionId as string, "particulars")
        );

    if (isLoading) {
        return <LoadingParticulars />;
    }

    if (isError) {
        console.error(error);
        showRequestErrorToast("toasts.vesselParticularsNotLoaded");
        return <></>;
    }

    if (data) {
        return (
            <div>
                {isLoading ? (
                    <LoadingParticulars />
                ) : (
                    <ReportDetails reportDetails={data} />
                )}
            </div>
        );
    }

    return <LoadingParticulars />;
};
