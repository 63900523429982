import { useQuery } from "react-query";
import { VesselParticularsResponse } from "../types/responses/VesselParticularsResponse";
import DataRequest from "./DataRequest";


export const getParticularsData = (inspectionId: string) => {
    return useQuery<VesselParticularsResponse>("vesselParticulars", async () => {
        const res = await DataRequest(inspectionId,"particulars");
        return res;
    })
}