import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { PaymentElement } from "@stripe/react-stripe-js";

/**
 * Stripe payment
 * @returns
 */
export const CardPayment = () => {

    return (
        <div>
            <div className="mt-3">
                <form>
                    <PaymentElement />
                </form>
            </div>
        </div>
    );
};
