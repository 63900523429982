import { createContext, useMemo, useState } from "react";
import { PaymentMethod } from "../../types/PaymentMethod";
import { PaymentStage } from "../../types/PaymentStage";
import { PaymentStageData } from "../../types/PaymentStageData";

export const PaymentContext = createContext<PaymentStage>({} as PaymentStage);

interface PaymentContextProps {
    children: React.ReactNode;
}

/**
 * This context provider will wrap the payment flow allowing data to be shared and enable
 * communication between the components
 * @returns
 */
export const PaymentContextProvider: React.FC<PaymentContextProps> = ({
    children
}) => {
    const [stage, setStage] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
        PaymentMethod.CARD
    );
    const [data, setData] = useState<PaymentStageData>({
        invoiceDetails: {
            name: null,
            organisation: null,
            email: null,
            address: null,
            city: null,
            country: null,
            addressLine2: null,
            postcode: null,
            termsAccepted: false
        },
        resaleCostDetails: {
            inspectionId: "",
            resalePrice: 0,
            totalResalePrice: 0,
            resalePaymentProcessingFee: 0,
            customer: "",
            shipName: "",
            inspectionNumber: "",
            organisation: ""
        },
        paymentDetails: {
            paymentId: "",
            error: "",
            email: "",
            invoiceRef: "",
        },
        paymentErrorMessage: ""
    });

    function update(_stage: number, _data: PaymentStageData) {
        setStage(_stage);
        setData(_data);
    }

    const providerObject = useMemo(
        () => ({
            stage,
            data,
            update,
            paymentMethod,
            setPaymentMethod
        }),
        [stage, data, paymentMethod]
    );

    return (
        <PaymentContext.Provider value={providerObject}>
            {children}
        </PaymentContext.Provider>
    );
};
