import ReactGA from "react-ga4";
import { UserAnalyticsConfig } from "../types/UserAnalyticsConfig";
import { UaEventOptions } from "react-ga4/types/ga4";

const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
let USER_CONFIG: UserAnalyticsConfig;

export const initializeAnalytics = (userAnalyticsConfig: UserAnalyticsConfig) => {
    if (!TRACKING_ID) {
        throw new Error("Cannot initalize analytics. Missing GA Measurement ID.");
    }

    if (!userAnalyticsConfig.inspectionId || !userAnalyticsConfig.customerId) {
        throw new Error("Cannot initialize analytics. Missing tracking details.")
    }

    USER_CONFIG = userAnalyticsConfig;

    ReactGA.initialize(TRACKING_ID, {
        gaOptions: {
            inspection_id: USER_CONFIG.inspectionId,
            customer_id: USER_CONFIG.customerId,
            report_access_id: `${USER_CONFIG.inspectionId}-${USER_CONFIG.customerId}`,
            debug_mode: true,
        }
    });

    ReactGA.set({ userId: `${USER_CONFIG.inspectionId}-${USER_CONFIG.customerId}` });
    ReactGA.send("pageview");
}

export const sendGAEvent = (category: string, action: string) => {
    action = action.trim().replaceAll(" ", "_");

    const options: UaEventOptions = {
        category,
        action,
        label: USER_CONFIG.inspectionId
    };

    ReactGA.event(options);
}
