import RequestDataAxios from "../idwal-api/api";
import { InvoiceDetails } from "../types/InvoiceDetails";
import { IPaymentDetails } from "../types/IPaymentDetails";
import { PaymentMethod } from "../types/PaymentMethod";
import { PaymentStage } from "../types/PaymentStage";
import { PaymentStageData } from "../types/PaymentStageData";
import { ResaleReportCostDetails } from "../types/ResaleReportCostDetails";

export async function getInspectionPaymentDetails(
    inspectionId: string
): Promise<ResaleReportCostDetails | null> {
    return new Promise((res) => {
        RequestDataAxios.get(
            `/Buyer/inspection/payment-details/${inspectionId}`
        )
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.status === 200
                ) {
                    res(response.data.data as ResaleReportCostDetails);
                }
                res(null);
            })
            .catch(() => {
                res(null);
            });
    });
}

export async function startPayment(
    inspectionId: string,
    email: string,
    paymentData: PaymentStage,
): Promise<IPaymentDetails | null> {
    const isBacs = paymentData.paymentMethod === PaymentMethod.BACS;
    const data = {
        email,
        inspectionId,
        paymentData: paymentData.data,
        isBacs: isBacs
    };

    return new Promise((res) => {
        RequestDataAxios.post(
            `/Buyer/inspection/start-payment/${inspectionId}`,
            data
        )
            .then((response) => {
                if (response && response.data && response.status === 201) {
                    console.log(response.data);
                    console.error(response.data);
                    res(response.data as IPaymentDetails);
                }
                res(null);
            })
            .catch(() => {
                res(null);
            });
    });
}

export async function sendInvoiceNotification(
    inspectionId: string,
    reportLicensingInfo: string,
    paymentMethod: PaymentMethod,
    invoiceDetails: InvoiceDetails,
    resaleCostDetails: ResaleReportCostDetails,
    invoiceRef: string
): Promise<PaymentStageData | null> {
    const data = {
        invoiceDetails,
        reportLicensingInfo,
        resaleCostDetails,
        paymentMethod,
        invoiceRef
    };

    return new Promise((res) => {
        RequestDataAxios.post(
            `/Buyer/inspection/invoice-notification/${inspectionId}`,
            data
        )
            .then((response) => {
                if (response && response.data && response.status === 201) {
                    res(response.data as PaymentStageData);
                }
                res(null);
            })
            .catch(() => {
                res(null);
            });
    });
}
