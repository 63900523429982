import { useQuery } from "react-query";
import { ComparisonResponse } from "../types/responses/gradeComparisonResponse";
import DataRequest from '../queries/DataRequest'

export const getGradingComparison = (inspectionId: string) => {
    return useQuery("comparison", async () => {
        const gradingData = await getGradeComparisonData(inspectionId);

        return gradingData;
    });
}

const getGradeComparisonData = async (inspectionId: string): Promise<ComparisonResponse> => {
    const res = await DataRequest(inspectionId,"comparison");
    return res;
}
