import { ReactElement } from "react";
import { Skeleton } from "primereact/skeleton";

export const LoadingParticulars = (): ReactElement => {
    const loadingShipName = (
        <div className="mb-3">
            <Skeleton width="100%" height="48px" className="mb-2"></Skeleton>
            <Skeleton width="100%" height="48px" className="mb-1"></Skeleton>
        </div>
    );

    const loadingShipDetails = (
        <div className="flex flex-row mb-2">
            <Skeleton height="17px" className="mr-3"></Skeleton>
            <Skeleton height="17px"></Skeleton>
        </div>
    );

    const loadingLine = (
        <div className="mb-5">
            <div className="flex">
                <div className="flex-column mr-4" style={{ flex: "1" }}>
                    {loadingShipName}
                    <div className="mb-4">
                        {loadingShipDetails}
                        {loadingShipDetails}
                        {loadingShipDetails}
                        {loadingShipDetails}
                    </div>
                    {loadingShipDetails}
                    {loadingShipDetails}
                    {loadingShipDetails}
                </div>
                <div style={{ flex: "1" }}>
                    <Skeleton
                        width="100%"
                        height="122px"
                        className="mb-2"
                    ></Skeleton>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <div className="field col-12 md:col-12">
                <div className="custom-skeleton p-2">
                    <ul className="m-0 p-0">{loadingLine}</ul>
                </div>
            </div>
        </div>
    );
};
