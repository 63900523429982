import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import styles from "./ReportLicensingDetails.module.css";
import { PaymentContext } from "./PaymentContext";
import { PaymentMethod } from "../../types/PaymentMethod";
import { useLocation } from "react-router-dom";
import { PaymentStages } from "../../types/PaymentStages";

export const ReportLicensingDetails = () => {
    const { t } = useTranslation("locale");
    const location = useLocation();
    const paymentContext = useContext(PaymentContext);

    const [paid] = useState(false);
    const [payColour, setPayColour] = useState(styles.blue);

    useEffect(() => {
        if (paid) {
            setPayColour(styles.green);
        }
    }, []);
    
    return (
        <Card
            header={
                <h2
                    style={{
                        border: "none",
                        marginLeft: "0",
                        whiteSpace: "nowrap"
                    }}
                >
                    {t("report_licensing_details.header")}
                </h2>
            }
        >
            <div className="mt-1 grid">
                <div className="col-12">
                    <div className="grid">
                        <div className="font-semibold pt-0 pb-1 col-12 md:col-12 lg:col-5">
                            {t("report_licensing_details.email")}
                        </div>
                        <div
                            className={`pt-0 pb-3 col-12 md:col-12 lg:col-7 ${styles.email}`}
                        >
                            {location.state.organisationEmail}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="font-semibold pt-0 pb-1 col-12 md:col-12 lg:col-5">
                            {t("report_licensing_details.name")}
                        </div>
                        <div className="pt-0 pb-3 col-12 md:col-12 lg:col-7">
                            {location.state.name
                                ? location.state.name
                                : location.state.organisation}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="font-semibold pt-0 pb-1 col-12 md:col-12 lg:col-5">
                            {t("report_licensing_details.organisation")}
                        </div>
                        <div className="pt-0 pb-4 col-12 md:col-12 lg:col-7">
                            {location.state.organisation}
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="mb-4 grid">
                <div className="col-12">
                    <div className="grid mt-2">
                        <div className="font-semibold pt-0 pb-1 col-12 md:col-12 lg:col-5">
                            {t("report_licensing_details.vessel")}
                        </div>
                        <div className="pt-0 pb-3 col-12 md:col-12 lg:col-7">
                            {paymentContext.data.resaleCostDetails.shipName}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="font-semibold pt-0 pb-1 col-12 md:col-12 lg:col-5">
                            {t(
                                "report_licensing_details.report_commissioned_for"
                            )}
                        </div>
                        <div className="pt-0 pb-3 col-12 md:col-12 lg:col-7">
                            {paymentContext.data.resaleCostDetails.organisation}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="font-semibold pt-0 pb-1 col-12 md:col-12 lg:col-5">
                            {t("report_licensing_details.inspection_reference")}
                        </div>
                        <div className="pt-0 pb-3 col-12 md:col-12 lg:col-7">
                            {
                                paymentContext.data.resaleCostDetails
                                    .inspectionNumber
                            }
                        </div>
                    </div>
                </div>
            </div>
            {paymentContext.stage !== PaymentStages.INVOICE_DETAILS &&
            paymentContext.paymentMethod === PaymentMethod.CARD ? (
                <div className={`p-3 ${payColour}`}>
                    <div className="flex flex-column pb-3">
                        <div className="flex flex-row justify-content-between pb-2">
                            <div className="flex flex-row">
                                {t("report_licensing_details.report_cost")}
                            </div>
                            <div className="flex flex-row font-semibold">
                                $
                                {paymentContext.data.resaleCostDetails.resalePrice.toFixed(
                                    0
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row justify-content-between">
                            <div className="flex flex-row">
                                {t("report_licensing_details.credit_card_fee")}
                            </div>
                            <div className="flex flex-row font-semibold">
                                $
                                {paymentContext.data.resaleCostDetails.resalePaymentProcessingFee.toFixed(
                                    0
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`flex flex-column pt-3 ${styles.totalPayBorder}`}
                    >
                        <div className="flex flex-row justify-content-between">
                            <div className="flex flex-row">
                                {paid
                                    ? t("report_licensing_details.total_paid")
                                    : t(
                                          "report_licensing_details.total_to_pay"
                                      )}
                            </div>
                            <div
                                className={`flex flex-row font-semibold line-height-1 ${styles.totalFigure}`}
                            >
                                $
                                {paymentContext.data.resaleCostDetails.totalResalePrice.toFixed(
                                    0
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`p-3 ${styles.blue}`}>
                    <div className="flex flex-column">
                        <div className="flex flex-row justify-content-between">
                            <div className="flex flex-row line-height-3">
                                {t("report_licensing_details.report_cost")}
                            </div>
                            <div
                                className={`flex flex-row font-semibold line-height-2 ${styles.totalFigure}`}
                            >
                                $
                                {paymentContext.data.resaleCostDetails.resalePrice.toFixed(
                                    0
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Card>
    );
};
