import { ReactElement } from "react";
import { getParticularsData } from "../../queries/particularsQueries";
import { showRequestErrorToast } from "../../services/ErrorService";
import { Loading } from "../loading/Loading";
import { useParams } from 'react-router';
import { Particulars as ParticularsGrid } from "@idwal/idwal-react-components";
import { ParticularsProps } from "@idwal/idwal-react-components/dist/types/Particulars";

export const Particulars = ():ReactElement => {
    const { inspectionId } = useParams();

    if (!inspectionId) {
        console.error("Particulars - No inspection id.");
        showRequestErrorToast("Particulars");
        return <div></div>;
    }

    const {data, isError, isLoading, error} = getParticularsData(inspectionId);

    if (isLoading || data == undefined) return <Loading />;

    if (isError) {
        console.error(error);
        showRequestErrorToast("Particulars");
        return <div></div>;
    }

    const excludedKeys = [
        "InspectionNumber",
        "InspectedDateTo",
        "InspectedDateFrom",
        "InspectedAt",
        "Grade",
        "InspectedType",
        "ShipName",
        "InspectionType"
    ]

    function camelize(str : string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word : string, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    const particularsProps : ParticularsProps = {
        particulars: {}
    };

    for (const [key, value] of Object.entries(data)) {
        if (excludedKeys.includes(key)) {
            continue;
        }

        particularsProps.particulars[camelize(key)] = value;
    }

    return (
        <ParticularsGrid particulars={particularsProps.particulars} />
    );
};