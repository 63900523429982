import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.css";
import { Trans } from "react-i18next";
import { Seller } from "../../types/Seller";
import { Claims } from "../../types/Claims";
import DataRequest from "../../queries/DataRequest";
import AuthToken from "../../queries/AuthToken";
import { FooterProps } from "../../types/FooterProps";

/**
 * Footer
 */
export const Footer = (props: FooterProps): ReactElement => {
    const { t } = useTranslation("locale");
    const [seller, setSeller] = useState(false);
    const [customerName, setCustomerName] = useState("");
    const [organisation, setOrganisation] = useState("");
    const pathname = window.location.pathname;
    const pathContent = pathname.split("/");
    const inspectionId = pathContent[2];
    const location = useLocation();
    const [showUser, setShowUser] = useState(false);
    const [footerColour, setFooterColour] = useState(styles.Footer);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const updateFooter = async () => {
            const seller = (await DataRequest(
                inspectionId,
                "seller"
            )) as Seller;
            const token = AuthToken();
            let claims;

            if (token) {
                claims = JSON.parse(atob(token.split(".")[1])) as Claims;
            }

            if (claims != undefined) {
                if (!claims.restamp || claims.customerEmail == seller.email) {
                    setSeller(true);
                    setCustomerName(seller.name);
                    setOrganisation(seller.organization.name);
                } else {
                    setSeller(false);
                    setCustomerName(claims.name);
                    setOrganisation(claims.organisation);
                }
            }
        };
        updateFooter();
    }, [props?.idwalCookieIsValid]);

    useEffect(() => {
        if (
            location.search &&
            (location.search.includes("token=") ||
                location.pathname.includes("seller"))
        ) {
            setShowUser(true);
            setFooterColour(styles.footerWithReportInfo);
        } else {
            setShowUser(false);
            setFooterColour(styles.Footer);
        }
    }, [location]);

    return (
        <footer
            className={`${footerColour} relative w-full py-6`}
            data-cy="footer"
        >
            <div className="max-width-wrapper">
                <div className="grid">
                    <div className="col-12 md:col-8 lg:col-9 xl:col-8">
                        <div
                            className={`${styles.footerText} font-semibold m-0 flex flex-column justify-content-center md:align-items-center md:justify-content-start md:flex-row gap-3 text-sm`}
                        >
                            <div className="flex-wrap">
                                {t("footer.copyright", { currentYear: currentYear })}
                            </div>
                            <div className="md:flex hidden">|</div>
                            <div>
                                <a href="https://www.idwalmarine.com/iso" target="_blank">
                                    {t("footer.iso")}
                                </a>
                            </div>
                            <div className="md:flex hidden">|</div>
                            <div className="flex gap-3">
                                <a href="https://www.idwalmarine.com/terms-and-conditions/inspection-reports">
                                    {t("footer.terms")}
                                </a>
                                <div>|</div>
                                <a href="https://www.idwalmarine.com/privacy-policy">
                                    {t("footer.privacy")}
                                </a>
                            </div>
                        </div>
                        {showUser ? (
                            <div className="m-0">
                                <br />
                                <p className="text-sm">
                                    <Trans i18nKey="footer.summary" t={t}>
                                        This report has been prepared and issued
                                        by Idwal Marine Services Ltd to its
                                        Customer,
                                        {{ customerName }} of {{ organisation }}
                                        , in accordance with, and subject to,
                                        the General Terms and Conditions of
                                        Idwal Marine Services Ltd, a copy of
                                        which can be obtained at
                                        <a
                                            className="font-semibold"
                                            href="https://www.idwalmarine.com/terms-and-conditions/inspection-reports"
                                        >
                                            https://www.idwalmarine.com/terms-and-conditions/inspection-reports
                                        </a>
                                        . Attention is particularly drawn to
                                        restrictions on reproduction and
                                        disclosure of, and limits on reliance
                                        on, this Report as more fully set out
                                        therein.
                                    </Trans>
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    {showUser ? (
                        <div className="col-12 md:col-4 lg:col-3 xl-col-4 md:text-right">
                            <div className="mx-0">
                                <p
                                    className={`${styles.footerText} font-semibold`}
                                >
                                    {seller
                                        ? t("footer.reportCommissionedBy")
                                        : t("footer.reportLicensedTo")}
                                    :
                                </p>
                                <p className={`${styles.footerText}`}>
                                    {customerName}
                                </p>
                                <br />
                                <p
                                    className={`${styles.footerText} font-semibold`}
                                >
                                    {t("footer.organisation")}:
                                </p>
                                <p className={`${styles.footerText}`}>
                                    {organisation}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </footer>
    );
};
