/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getGrades } from "../../queries/gradingQueries";
import { showRequestErrorToast } from "../../services/ErrorService";
import { Loading } from "../loading/Loading";
import { useParams } from "react-router";
import {
    GradeAccordion,
    GradeLozenge,
    GradeCell
} from "@idwal/idwal-react-components";
import { sendGAEvent } from "../../services/AnalyticsService";
import { GA_BUTTON_EVENT } from "../../constants/GaEvents";
import { GradeAccordionTabProps } from "@idwal/idwal-react-components/dist/types/GradeAccordionProps";
import { ImageAlbumGalleryComponent } from "../vessel-info/ImageAlbumGallery";
import { getPhotos } from "../../queries/photosQueries";
import { getDownloads } from "../../queries/downloadQueries";
import { GetPhotosResponse } from "../../types/responses/GetPhotosResponse";
import { DocumentResponse } from "../../types/responses/documentResponse";

export const Grading = (): ReactElement => {
    const { t } = useTranslation("locale");
    const { inspectionId } = useParams();

    if (!inspectionId) {
        console.error("No inspection id.");
        showRequestErrorToast("Grading");
        return <div></div>;
    }

    const {data, isError, error, isLoading} = getGrades(inspectionId);
    const { data: photosData } = getPhotos(inspectionId);
    const { data: documentData } = getDownloads(inspectionId ?? "");

    if (isLoading || data == undefined) return <Loading />;

    if (isError) {
        console.error(error);
        showRequestErrorToast("Grading");
        return <div></div>;
    }

    const gradingData = data.gradingInformation;

    const createManagementGradeCells = () => {
        const managementGradeCells = [];
        for (
            let index = 0;
            index < gradingData.managementGrades.length;
            index++
        ) {
            managementGradeCells.push(
                <div
                    key={index}
                    className={`${
                        index % 2 == 0 ? "lg:pr-0" : "lg:pl-1"
                    } grade-cat-bottom field col-12 lg:col-6`}
                >
                    <GradeCell
                        name={gradingData.managementGrades[index].name}
                        score={gradingData.managementGrades[index].score}
                    ></GradeCell>
                </div>
            );
        }

        return managementGradeCells;
    };

    const accordionTabStyle = (
        headerName: string,
        grade: number
    ): ReactElement => {
        return (
            <div className="flex justify-content-between align-items-center w-full">
                <span className="p-accordion-header-text">{headerName}</span>
                <div className="pl-3">
                    <GradeLozenge grade={grade}></GradeLozenge>
                </div>
            </div>
        );
    };

    const handleAnalyticsEvent = (action: string) => {
        sendGAEvent(GA_BUTTON_EVENT, action);
    };

    const gradeAccordionTabs = (): GradeAccordionTabProps[] => {
        return data.gradingInformation.vesselGrades.map((vesselGrade) => {
            const conditionSummary = data.conditionSummaries.find(
                (e) => e.title == vesselGrade.name
            );
            const summaryText = vesselGrade.summaryText
                .replace(/\n\s*\n\s*\n/g, "\n\n")
                .trim();

            return {
                name: vesselGrade.name,
                score: vesselGrade.score,
                summaryText: summaryText,
                conditionSummary: conditionSummary?.description,
                notableItems: conditionSummary?.notableItems,
                imageGallery: {
                    albumName: vesselGrade.name,
                    component: galleryComponent(vesselGrade.name, photosData, documentData)
                }
            } as GradeAccordionTabProps;
        });
    };

    const galleryComponent = (albumName: string, photos?: GetPhotosResponse[], documentData?: DocumentResponse)  => {
        if (!photos?.find((album) => album.albumName === albumName)) {
            return <></>;
        }

        return (
            ImageAlbumGalleryComponent(photos, documentData, albumName, "align-self-start", "icon-only")
        )
    }

    return (
        <div>
            <p className="paragraph-max-width">{t("report.gradingPreamble")}</p>
            <div className="mt-5">
                <div className="formgrid grid">
                    <div className="lg:pr-0 grade-cat-bottom field col-12 lg:col-6">
                        <GradeCell
                            name={gradingData.condition.name}
                            score={gradingData.condition.score}
                        ></GradeCell>
                    </div>
                    <div className="lg:pl-1 grade-cat-bottom field col-12 lg:col-6 ">
                        <GradeCell
                            name={gradingData.management.name}
                            score={gradingData.management.score}
                        ></GradeCell>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <GradeAccordion
                    accordionTabStyle={accordionTabStyle}
                    sendAnalyticsEvent={handleAnalyticsEvent}
                    tabs={gradeAccordionTabs()}
					showImageGallery={true}
                />
            </div>
            <div className="mt-5 mb-5">
                <div className="formgrid grid">
                    {createManagementGradeCells()}
                </div>
            </div>
        </div>
    );
};
