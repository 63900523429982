import { useQuery } from "react-query";
import DataRequest from '../queries/DataRequest'
import {DocumentResponse} from "../types/responses/documentResponse";


export const getDownloads = (inspectionId: string) => {
    return useQuery(
        ["documents", inspectionId],
        () => getDocuments(inspectionId),
        {
            staleTime: 5 * 60 * 1000
        });
}

const getDocuments = async (inspectionId: string): Promise<DocumentResponse> => {
    return DataRequest(inspectionId,"documents");
}
