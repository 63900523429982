import {ImageAlbumGallery} from "@idwal/idwal-react-components";
import {DocumentResponse} from "../../types/responses/documentResponse";
import {GetPhotosResponse} from "../../types/responses/GetPhotosResponse";
import {AppContextType} from "../../types/AppContextType";
import {AppContext} from "../../App";
import React from "react";


export const ImageAlbumGalleryComponent = (data: GetPhotosResponse[] | undefined, documentData: DocumentResponse | undefined, selectedArea?: string, classNames?: string, openButtonStyle?: "default" | "icon-only" | undefined)  => {
    const { restrictedView } = React.useContext(AppContext) as AppContextType;

    if (!data) {
        return <></>
    }

    const zipUrl = documentData?.zipElement?.value?.url ?? "";

    if (data.length === 0) {
        return <></>
    }

    const defaultAlbum = data.filter(album => album.isDefault)[0];
    data = data.filter(album => !album.isDefault);

    return  (
        <div className={classNames}>
            <ImageAlbumGallery
                autoOpen={false}
                zipUrl={zipUrl}
                albums={data}
                defaultAlbum={defaultAlbum}
                downloadDisabled={restrictedView}
                selectedAlbumKey={selectedArea}
                openButtonStyle={openButtonStyle}
            />
        </div>
    );
}