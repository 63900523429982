/* eslint-disable @typescript-eslint/no-explicit-any */
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import {
    useContext,
    useEffect,
    useRef,
    useState,
    MutableRefObject
} from "react";
import { PaymentContext } from "./PaymentContext";
import { GetDomainFromEmail } from "../../helpers/emails";
import { checkPaidAccess } from "../../services/TokenService";
import { PaymentStages } from "../../types/PaymentStages";
import { useLocation } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { sendGAEvent } from "../../services/AnalyticsService";
import {
    GA_BUTTON_EVENT,
    GA_SHARE_CLIPBOARD,
    GA_VIEW_REPORT
} from "../../constants/GaEvents";
import { setAccessTokenCookie } from "../../helpers/cookieCreator";
import ReactGA from "react-ga4";

/**
 * Card payment success page
 * @returns
 */
export const CardPaymentSuccess = () => {
    const { t } = useTranslation("locale");
    const paymentContext = useContext(PaymentContext);
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const organisation = location.state.organisation;
    const invoiceEmail = paymentContext.data.invoiceDetails.email ?? "";
    const inspectionId = paymentContext.data.resaleCostDetails.inspectionId;
    const email = location.state.email;

    const domain = GetDomainFromEmail(email);

    const tokenRef: MutableRefObject<string | null> = useRef(null);

    useEffect(() => {
        const fetchToken = async () => {
            setLoading(true);
            const data = await checkPaidAccess(inspectionId, email);
            tokenRef.current = data;
            setAccessTokenCookie(
                JSON.stringify({
                    paid: true
                }),
                inspectionId
            );
            setLoading(false);
        };
        if (email) {
            fetchToken();
        }
    }, []);

    function viewReportClick() {
        ReactGA.event({
            action: GA_VIEW_REPORT,
            category: GA_BUTTON_EVENT,
            label: "View Report"
        });
        if (tokenRef.current) {
            window.open(
                `${window.location.origin}/inspections/${inspectionId}/access?token=${tokenRef.current}`,
                "_blank"
            );
        } else {
            paymentContext.update(PaymentStages.PAYMENT_FAILURE, {
                ...paymentContext.data,
                paymentDetails: {
                    ...paymentContext.data.paymentDetails,
                    error: "Error"
                }
            });
        }
    }

    const onCopy = (e: any) => {
        sendGAEvent(GA_BUTTON_EVENT, GA_SHARE_CLIPBOARD);
        e.currentTarget.blur();
    };

    return (
        <Card title={t("payments.payment_success.card_title")}>
            <div>
                <p>
                    {t("payments.payment_success.card_info")} {invoiceEmail}.
                </p>

                <div className="flex w-full mt-4 justify-content-center mt-2">
                    <Button
                        className="lg:w-auto w-full"
                        data-cy="payment-view-report-button"
                        label={t("payments.payment_success.view_report")}
                        loading={loading}
                        onClick={viewReportClick}
                    />
                </div>

                <p className="mt-4">
                    <Trans i18nKey="payments.payment_success.queries" t={t}>
                        If you have any questions or need any further
                        information please
                        <a
                            target="_blank"
                            href="https://www.idwalmarine.com/contact-us"
                        >
                            contact us.
                        </a>
                    </Trans>
                </p>

                <h3 className="mt-4 pb-2">
                    {t("payments.payment_success.organisation_access_title")}
                </h3>

                <div className="mt-1 gap-3 flex align-items-center">
                    <p>
                        <Trans i18nKey="payments.payment_success.organisation_share_info">
                            You can share full access to this report with all
                            your colleages at {{ organisation }}. They will need
                            an @{{ domain }} email address to gain access.
                        </Trans>
                    </p>
                    <CopyToClipboard
                        text={`${window.location.origin}/inspections/${inspectionId}/access`}
                    >
                        <Button
                            tooltip={t("report.copiedToClipboard")}
                            tooltipOptions={{
                                event: "focus",
                                position: "left",
                                hideDelay: 2000
                            }}
                            onClick={(e: any) => onCopy(e)}
                            icon="pi pi-copy"
                            className="share-copy-link p-button-rounded p-button-text p-button-icon-only"
                        />
                    </CopyToClipboard>
                </div>
            </div>
        </Card>
    );
};
