import React from "react";
import ReactDOM from "react-dom/client";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@idwal/idwal-react-components/css/idwal-theme.css";
import "primeicons/primeicons.css";
import "./index.css"
import common_en from "@idwal/idwal-react-components/translations/en/common.json";
import app_locale_en from "./translations/en/locale.json";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from "./App";

import { AwsConfigAuth } from "./cognito/authConfig";
import { Amplify } from "aws-amplify";

Amplify.configure(AwsConfigAuth);

i18next.init({
    interpolation: { escapeValue: false },
    lng: "en",
    resources: {
        en: {
            common: common_en,
            locale: app_locale_en
        }
    }
});


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </React.StrictMode>
);
