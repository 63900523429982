import * as yup from "yup";
import { getSpecialCharacters } from "@idwal/idwal-utilities/lib/helpers";
import countries from "../components/payment/countries.json";

function isSpecialCharactersFree(str: string) {    
    return getSpecialCharacters().test(str);
}

export default yup.object().shape(
    {
        name: yup.string().required().trim().test(
            'invalid_character',
            (input: string) => !isSpecialCharactersFree(input)
        ),
        organisation: yup.string().required().trim().test(
            'invalid_character',
            (input: string) => !isSpecialCharactersFree(input)
        ),
        email: yup.string().required().trim().test(
            'invalid_character',
            (input: string) => !isSpecialCharactersFree(input)
        ).email(),
        address: yup.string().required().trim().test(
            'invalid_character',
            (input: string) => !isSpecialCharactersFree(input)
        ),
        addressLine2: yup.string().nullable().transform((value) => value?.trim() || null).test(
            'invalid_character',
            (value) => value ? !isSpecialCharactersFree(value) : true
        ),
        postcode: yup.string().nullable().transform((value) => value?.trim() || null).test(
            'invalid_character',
            (value) => value ? !isSpecialCharactersFree(value) : true
        ),
        city: yup.string().required().trim().test(
            'invalid_character',
            (input: string) => !isSpecialCharactersFree(input)
        ),
        country: yup.string().trim().oneOf(countries).required(),
        termsAccepted: yup.boolean().oneOf([true]).required(),
    },
    [
        ["postcode", "postcode"],
        ["addressLine2", "addressLine2"], 
    ]
);