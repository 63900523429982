import { TabView, TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { ReactElement, useState } from "react";
import { ITabConfig } from "../../types/ITabConfig";
import { ITabProps } from "../../types/ITabProps";
import { sendGAEvent } from "../../services/AnalyticsService";
import {
    GA_BUTTON_EVENT,
    GA_TAB_DOCUMENTS,
    GA_TAB_GRADING,
    GA_TAB_ITEMS,
    GA_TAB_PARTICULARS,
    GA_TAB_SUMMARY,
    GA_TAB_GRADE_COMPARISON
} from "../../constants/GaEvents";

const TAB_ENUM = {
    SUMMARY: 0,
    PARTICULARS: 1,
    NOTABLE_ITEMS: 2,
    GRADING: 3,
    DOCUMENTS: 4,
    GRADE_COMPARISON: 5
}

export const Tabs = (props: ITabProps): ReactElement => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleTabChange = (e: TabViewTabChangeEvent) => {
        setActiveIndex(e.index);

        const actionMap: { [name: string]: string } = {
            [TAB_ENUM.SUMMARY]: GA_TAB_SUMMARY,
            [TAB_ENUM.PARTICULARS]: GA_TAB_PARTICULARS,
            [TAB_ENUM.NOTABLE_ITEMS]: GA_TAB_ITEMS,
            [TAB_ENUM.GRADING]: GA_TAB_GRADING,
            [TAB_ENUM.DOCUMENTS]: GA_TAB_DOCUMENTS,
            [TAB_ENUM.GRADE_COMPARISON]: GA_TAB_GRADE_COMPARISON
        }
        const action: string = actionMap[e.index];

        if (action) {
            sendGAEvent(GA_BUTTON_EVENT, action);
        }
    }

    return (
        <TabView className="" activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e)}>
            {props.tabs.map((tab: ITabConfig) => {
                return (
                    <TabPanel key={tab.title} header={tab.title}>
                        {tab.content}
                    </TabPanel>
                )
            })}
        </TabView>
    );
};
