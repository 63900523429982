import { TFunction } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function ExpiredLink(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "error",
        closable: false,
        detail: (
            <p className="errorText">
                {t("toasts.expiredLink")}
            </p>
        )
    }
}
