import { TFunction, Trans } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function AuthenticatedCustomer(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "success",
        closable: false,
        detail: (
            <p className="errorText">
                <Trans i18nKey="toasts.authenticatedCustomer" t={t}>
                    We have sent you a pre-authenticated link, please check your email - this link will expire in <strong>15 minutes</strong>
                </Trans>
            </p>
        )
    }
}
