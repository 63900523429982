import axios from "axios";
import AuthToken from "../queries/AuthToken";
const api = `https://${process.env.REACT_APP_IDWAL_API_ID}.execute-api.eu-west-2.amazonaws.com/${process.env.REACT_APP_ASIMS_ENV}`;

const token = AuthToken();

export default axios.create({
    baseURL: api + "/",
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
    }
});
