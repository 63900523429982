import axios from "axios";
import AuthToken from '../queries/AuthToken'


const DataRequest = async (inspectionId: string, section: string,tokenRequired  = true) => {
    const url = process.env.REACT_APP_API_URL + section + "/" + inspectionId;
    const token = AuthToken();
    
    if(tokenRequired && !token) {
        return {};
    }
    
    return axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then(result => result.data);
}

export default DataRequest;

