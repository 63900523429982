import { MessagesMessage } from "primereact/messages";
import { TFunction, Trans } from "react-i18next";
import React from "react";

export default function InvalidWithLink(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "error",
        closable: false,
        content: (
            <React.Fragment>
                <i className="pi pi-exclamation-triangle icon" />
                <p className="errorText">
                    <Trans i18nKey="toasts.invalidWithLink" t={t}>
                        Sorry, there is an issue with the report access. Please try
                        again or
                        <a
                            className="contactUsText"
                            href="https://www.idwalmarine.com/contact-us"
                            target="_blank"
                        >
                            contact us
                        </a>
                        .
                    </Trans>

                </p>
            </React.Fragment>
        )
    };
}