import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useTranslation } from "react-i18next";
import { PaymentMethod } from "../../types/PaymentMethod";
import { PaymentContext } from "./PaymentContext";
import { useContext } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import {
    GA_BACS_TOGGLE,
    GA_BUTTON_EVENT,
    GA_CREDIT_CARD_TOGGLE
} from "../../constants/GaEvents";
import ReactGA from "react-ga4";

/**
 * Toggle to update payment method type in the context state
 * @param props type of payment method
 * @returns
 */
export const PaymentTypeToggle = (props: { type: PaymentMethod }) => {
    const { t } = useTranslation("locale");
    const paymentContext = useContext(PaymentContext);

    function getTitle() {
        if (props.type === PaymentMethod.CARD) {
            return t("payments.details.card");
        }
        return t("payments.details.bacs");
    }

    return (
        <div
            onClick={() => {
                paymentContext.setPaymentMethod(props.type);
                if (props.type === PaymentMethod.CARD) {
                    ReactGA.event({
                        action: GA_CREDIT_CARD_TOGGLE,
                        category: GA_BUTTON_EVENT,
                        label: "Credit Card"
                    });
                } else {
                    ReactGA.event({
                        action: GA_BACS_TOGGLE,
                        category: GA_BUTTON_EVENT,
                        label: "BACS"
                    });
                }
            }}
            className={
                paymentContext.paymentMethod === props.type
                    ? "payment-toggle border-1 border-round border-solid lg:mb-0 xl:mb-0 mb-3"
                    : "payment-toggle lg:mb-0 border-round xl:mb-0 mb-3"
            }
        >
            <div className="flex flex-grow-1 md:align-items-start align-items-center">
                <RadioButton
                    data-cy={`payment-method-radio-${props.type}`}
                    onChange={() => paymentContext.setPaymentMethod(props.type)}
                    checked={paymentContext.paymentMethod === props.type}
                />
                <label className="ml-2 select-none cursor-pointer">
                    {getTitle()}
                </label>

                {props.type === PaymentMethod.CARD && (
                    <>
                        <Tooltip target=".fast-track-icon" />

                        <div className="md:block hidden">
                            <Image
                                data-pr-tooltip={t(
                                    "payments.details.instant_access"
                                )}
                                data-pr-position="right"
                                data-pr-at="right center"
                                className="fast-track-icon ml-3 flex justify-content-center align-items-center"
                                src="/fast-track.svg"
                            />
                        </div>

                        <div className="md:hidden block ml-3">
                            <Tag
                                rounded
                                severity="info"
                                value={
                                    <div className="flex align-items-center justify-content-center">
                                        <Image
                                            width="13px"
                                            className="fast-track-icon pr-1"
                                            src="/fast-track.svg"
                                        />
                                        <span
                                            style={{
                                                fontSize: 12
                                            }}
                                        >
                                            {t(
                                                "payments.details.instant_access"
                                            ).toUpperCase()}
                                        </span>
                                    </div>
                                }
                            ></Tag>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
