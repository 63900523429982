import identityServerAxios from "../identity-server/api";
import UserInteractionAxios from "../user-interactions-server/api";
import { AccessTokenResponse } from "../types/AccessTokenResponse";
import { GetAccessTokenRequest, GetAccessTokenApiResponse, SellerAccessTokenResponse } from "../types/GetAccessToken";


export async function hasAuthorizedAccess(
    inspectionId: string,
    customerEmail: string
) {
    return new Promise<AccessTokenResponse>((req, reject) => {
        identityServerAxios
            .post("authorize", {
                inspectionId: inspectionId,
                customerEmail: customerEmail
            })
            .then((response) => {
                req(response.data as AccessTokenResponse);
            })
            .catch((err) => {
                if (err.response.status && err.response.status === 403) {
                    req(err.response.data as AccessTokenResponse);
                } else {
                    console.error(err);
                    reject(err);
                }
            });
    });
}

export async function hasValidtoken(
    authToken: string,
    inspectionId: string
) {
    return new Promise<boolean>((res, rej) => {
        identityServerAxios
            .post("access", null, { headers: {
                Authorization: 'Bearer ' + authToken
            }})
            .then((response) => {
                if (response?.data?.success && response?.data?.data?.inspectionId === inspectionId) {
                    res(true);
                }
                res(false);
            })
            .catch((err) => {
                if (err.response.status && err.response.status === 403) {
                    res(err.response?.data?.success ?? false);
                } else {
                    rej(err);
                }
            })
    });
}

export async function getAccessFromRefresh(
    refreshToken: string
) {
    return new Promise((res, rej) => {
        identityServerAxios
            .post("refresh", { token: refreshToken })
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                if (err.response.status && err.response.status === 403) {
                    res(err.response);
                } else {
                    rej(err);
                }
            })
    });
}

export async function getAccessToken(getAccessTokenRequest: GetAccessTokenRequest) {
    return new Promise<GetAccessTokenApiResponse>((res, rej) => {
        UserInteractionAxios
            .post("access-token", getAccessTokenRequest)
            .then((response) => {
                res(response as GetAccessTokenApiResponse);
            })
            .catch((err) => {
                rej(err.response as GetAccessTokenApiResponse);
            });
    });
}

export async function getSellerAccessToken(idToken: string) {
    return new Promise<SellerAccessTokenResponse>((res, rej) => {
        identityServerAxios
            .get("generateSellersToken?idtoken=" + idToken)
            .then((response) => {
                res(response.data as SellerAccessTokenResponse);
            })
            .catch((err) => {
                rej(err.response.data as SellerAccessTokenResponse);
            });
    });
}


