import { MessagesMessage } from "primereact/messages";
import { TFunction } from "react-i18next";

export default function ValidateEmail(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "info",
        closable: false,
        detail: (
            <p className="errorText">
                {t("toasts.validateEmail")}
            </p>
        )
    };
}
