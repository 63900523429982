import { MessagesMessage } from "primereact/messages";
import { TFunction } from "react-i18next";

export default function PaymentEmail(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "success",
        closable: false,
        detail: (
            <p className="errorText">
                {t("toasts.paymentEmailSent")}
            </p>
        )
    };
}
