import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { ReactElement } from "react";
import { VesselGallery } from "./vessel-info/vesselGallery";
import { VesselParticulars } from "./vessel-info/vesselParticulars";

export const VesselInformation = (): ReactElement => {
    return (
        <div className="grid mt-3">
            <div className="col-12 lg:col-6">
                <VesselGallery />
            </div>
            <div className="col-12 lg:col-6">
                <VesselParticulars />
            </div>
        </div>
    );
};
