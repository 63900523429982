import Cookies from 'universal-cookie';

const COOKIE_NAME = 'idwalcookie' + process.env.REACT_APP_ASIMS_ENV;

const authToken = () => {
    let token = localStorage.getItem("accessToken");

    if (token == undefined) {

        const cookies = new Cookies();
        const cookieResult = cookies.get(COOKIE_NAME);

        if (cookieResult) {
            token = cookieResult;
        }
    }
    return token;
}

export default authToken;
