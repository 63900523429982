import { useQuery } from "react-query";
import { GradingData, GradingResponse, GradingSummaryResponse } from "../types/responses/gradingResponse";
import DataRequest from '../queries/DataRequest'

export const getGrades = (inspectionId: string) => {
    return useQuery("grading", async () => {
        const gradingData = await getGradingData(inspectionId);

        const gradingSummaries = await getGradingSummaries(inspectionId);

        gradingData.vesselGrades.forEach(grade => {
            grade.summaryText = gradingSummaries.find(e => e.title == grade.name)?.description ?? "";
        });

        gradingSummaries.forEach(summary => {
            summary.grade = gradingData.vesselGrades.find(g => g.name == summary.title)?.score;
        });

        const data : GradingData = {
            conditionSummaries: gradingSummaries,
            gradingInformation: gradingData
        }

        return data;
    });
}

const getGradingData = async (inspectionId: string): Promise<GradingResponse> => {
    const res = await DataRequest(inspectionId,"grading");
    return res;
}

const getGradingSummaries = async (inspectionId: string): Promise<GradingSummaryResponse[]> => {
    const res = await DataRequest(inspectionId,"conditionSummaries");
    return res;
}
