import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { PaymentTypeToggle } from "./PaymentTypeToggle";
import { PaymentMethod } from "../../types/PaymentMethod";
import { useTranslation } from "react-i18next";
import { CardPayment } from "./CardPayment";
import { PaymentContext } from "./PaymentContext";
import { useContext, useEffect, useRef } from "react";
import { BacsPayment } from "./BACSPayment";
import { PaymentFooter } from "./PaymentFooter";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Messages } from "primereact/messages";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

/**
 * Container for the payment details/form
 * @returns
 */
export const PaymentDetails = () => {
    const { t } = useTranslation("locale");
    const msgs = useRef<Messages>(null);
    const paymentContext = useContext(PaymentContext);

    const option = {
        clientSecret: paymentContext.data.paymentDetails.paymentId
    };

    useEffect(() => {
        if (paymentContext.data.paymentErrorMessage !== "") {
            console.log(paymentContext.data.paymentErrorMessage);
            msgs.current?.show([
                {
                    sticky: true,
                    severity: "error",
                    summary: "",
                    detail: paymentContext.data.paymentErrorMessage,
                    closable: true
                }
            ]);
        }
    }, [paymentContext.data]);

    return (
        <Card title={t("payments.details.title")}>
            <div className="lg:flex xl:flex w-full gap-3 justify-content-between flex-grow-1">
                <PaymentTypeToggle type={PaymentMethod.CARD} />
                <PaymentTypeToggle type={PaymentMethod.BACS} />
            </div>
            <Elements stripe={stripePromise} options={option}>
                <div className="mt-5">
                    {paymentContext.paymentMethod === PaymentMethod.CARD && (
                        <div>
                            <p>
                                <span className="font-semibold">
                                    {t("payments.details.card_info_title")}
                                </span>
                                {t("payments.details.card_info")}
                            </p>
                            <Messages ref={msgs} />
                            <CardPayment />
                        </div>
                    )}
                    {paymentContext.paymentMethod === PaymentMethod.BACS && (
                        <BacsPayment />
                    )}

                    <PaymentFooter />
                </div>
            </Elements>
        </Card>
    );
};
