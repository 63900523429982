import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Trans, useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import countries from "./countries.json";
import { PaymentStages } from "../../types/PaymentStages";
import { PaymentContext } from "./PaymentContext";
import { startPayment } from "../../services/PaymentService";
import { InvoiceDetails } from '../../types/InvoiceDetails';
import { useForm , Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import invoiceSchema from "../../schemas/invoiceDetails";
import {
    GA_BUTTON_EVENT,
    GA_SUBMIT_INVOICE_DETAILS_FORM
} from "../../constants/GaEvents";
import ReactGA from "react-ga4";

export const InvoiceDetailsForm = () => {
    const { t } = useTranslation("locale");
    const paymentContext = useContext(PaymentContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const inspectionId = paymentContext.data.resaleCostDetails.inspectionId;

    useEffect(() => {
        if (document.cookie.indexOf(inspectionId) != -1) {
            navigate(`/inspections/${inspectionId}/access`);
        }
    }, []);

    const defaultValues:InvoiceDetails = {
        name: getValueFromUrlSearchParams(searchParams, "name"),
        organisation: getValueFromUrlSearchParams(searchParams, "organisation"),
        email: getValueFromUrlSearchParams(searchParams, "email"),
        address: "",
        addressLine2: "",
        postcode: "",
        city: "",
        country: "",
        termsAccepted: false,
    }
      
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
    } = useForm({ resolver: yupResolver(invoiceSchema), mode: "onChange"});
    
    const onSubmit = async (formData: InvoiceDetails) => {
        setLoading(true);
        
        const paymentData = paymentContext;
        paymentData.data.invoiceDetails = { ...formData };
            
        ReactGA.event({
            action: GA_SUBMIT_INVOICE_DETAILS_FORM,
            category: GA_BUTTON_EVENT,
            label: "Next: Payment"
        });

        if (formData) {
            const stripePaymentDetails = await startPayment(
                inspectionId,
                location.state.email,
                paymentContext
            );

            if (stripePaymentDetails) {
                paymentContext.update(PaymentStages.PAYMENT_DETAILS, {
                    invoiceDetails: paymentData.data.invoiceDetails,
                    paymentDetails: stripePaymentDetails,
                    resaleCostDetails: paymentContext.data.resaleCostDetails,
                    paymentErrorMessage: ""
                });
            } else {
                console.error("Failed to start stripe payment process");
            }
        }
        setLoading(false);
    };

    function getFormErrorMessage (name: string, dataCy?: string): JSX.Element | undefined {        
        const errorType = errors[name as keyof InvoiceDetails]?.type + "";
        return errors[name as keyof InvoiceDetails]
            && <small data-cy={dataCy && `${name}-error-message`} className={`p-0 mt-2 ${ errors[name] ? "p-error block": null}`}>{t(`validation.${errorType}${errorType && ['required'].includes(errorType) ? '.'+name : ''}`)}</small>

    }

    function getValueFromUrlSearchParams(searchParams: URLSearchParams, key: string) {        
        if (paymentContext.data.invoiceDetails[key as keyof InvoiceDetails]) {
            return paymentContext.data.invoiceDetails[key as keyof InvoiceDetails];
        }

        let name;
        switch(key) {
            case "name": 
                name = searchParams.get("customerName");                
                break;
            case "email": 
                name = searchParams.get("customerEmail");                
                break;
            default:
                name = searchParams.get(key);
                break;
        }
        return name ?? location.state[key];
    }

    useEffect(() => {
        reset({ ...defaultValues });
    }, []);

    return (
        <Card
            header={
                <h2
                    style={{
                        border: "none",
                        marginLeft: "0",
                        whiteSpace: "nowrap"
                    }}
                >
                    {t("payments.invoice_details_form.header")}
                </h2>
            }
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-1">
                    <div className="field">
                        <label>{t("payments.invoice_details_form.name")}</label>
                        <InputText  className={`col-12 ${ errors.name ? "p-invalid" : null}`} data-cy="name-field" {...register("name")}/>
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="field">
                        <label>{t("payments.invoice_details_form.organisation")}</label>
                        <InputText  className={`col-12 ${ errors.organisation ? "p-invalid" : null}`} data-cy="organisation-field" {...register("organisation")}/>
                        {getFormErrorMessage('organisation')}
                    </div>
                    <div className="field">
                        <label>{t("payments.invoice_details_form.email")}</label>
                        <InputText  className={`col-12 ${ errors.email ? "p-invalid" : null}`} data-cy="email-field" {...register("email")}/>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="field">
                        <label>{t("payments.invoice_details_form.address_line_1")}</label>
                        <InputText  className={`col-12 ${ errors.address ? "p-invalid" : null}`} data-cy="address-1-field" {...register("address")}/>
                        {getFormErrorMessage('address')}
                    </div>
                    <div className="field">
                        <label>{t("payments.invoice_details_form.address_line_2")}<span style={{ fontSize: "13px" }}>{t("payments.invoice_details_form.optional")}</span></label>
                        <InputText  className={`col-12 ${ errors.addressLine2 ? "p-invalid" : null}`} data-cy="address-2-field" {...register("addressLine2")}/>
                        {getFormErrorMessage('addressLine2')}
                    </div>
                    <div className="field">
                        <label>{t("payments.invoice_details_form.city")}</label>
                        <InputText  className={`col-12 ${ errors.city ? "p-invalid" : null}`} data-cy="city-field" {...register("city")}/>
                        {getFormErrorMessage('city')}
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 lg:col-6 px-2">
                            <label>{t("payments.invoice_details_form.country")}</label>
                            <Controller
                                control={control}
                                name="country"
                                render={({
                                    field: {value: selectedCountry, ...countrySelector},
                                }) => (
                                        <Dropdown
                                            inputRef={countrySelector.ref}
                                            placeholder={t("payments.invoice_details_form.please_select")}
                                            className={`w-full ${ errors.country ? "p-invalid" : null}`}
                                            data-cy="country-field"
                                            options={countries}
                                            filter
                                            value={selectedCountry}
                                            {...countrySelector}
                                        />
                                    )}
                                />
                            {getFormErrorMessage('country')}
                        </div>

                        <div className="field col-12 lg:col-6 px-2">
                            <label style={{ whiteSpace: "nowrap" }}>{t("payments.invoice_details_form.postcode")}<span style={{ fontSize: "13px" }}>{t("payments.invoice_details_form.optional")}</span></label>
                            <InputText className={`${ errors.postcode ? "p-invalid" : null}`} data-cy="postcode-field" {...register("postcode")}/>
                            {getFormErrorMessage('postcode')}
                        </div>
                    </div>
                </div>

                <div className="flex justify-content-center mt-2">
                    <Controller
                        control={control}
                        name="termsAccepted"
                        render={({
                            field: {
                            value: isTermsAndConditionsChecked,
                            ...termsAndConditionsField
                            },
                        }) => (
                        <Checkbox
                            checked={isTermsAndConditionsChecked}
                            className={errors.termsAccepted ? "p-invalid" : ""}
                            data-cy="t-and-c-field"
                            {...termsAndConditionsField}
                            inputRef={termsAndConditionsField.ref}
                        />
                        )}
                    />
                    <p className="ml-3" style={{ fontSize: "13px" }}>
                        <Trans
                            i18nKey="payments.invoice_details_form.t_and_c"
                            t={t}
                        >
                            I understand that this report is for my sole use only
                            and I will not reproduce any part of the report. I have
                            read and agree to Idwal’s full
                            <a
                                href="https://www.idwalmarine.com/terms-conditions"
                                target="_blank"
                                className="font-semibold"
                            >
                                terms & conditions
                            </a>
                            .
                        </Trans>
                    </p>
                </div>
                {errors.termsAccepted ? (
                    <div className="col-12 p-0 mt-2">
                        <small
                            data-cy="t-and-c-error-message"
                            className="p-error block"
                        >
                            {t('payments.invoice_details_form.termsNotAccepted')}
                        </small>
                    </div>
                ) : null}
                <div className="flex justify-content-center">
                    <Button
                        className="mt-4 w-full md:w-auto"
                        loading={loading}
                        label={t(
                            "payments.invoice_details_form.next_button_payment"
                        )}
                        type="submit"
                        data-cy="next-payment-button"
                    />
                </div>
            </form>
        </Card>
    );
};
