import { TFunction, Trans } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function ReportRequestAccessDisabled(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "error",
        closable: false,
        icon: "pi pi-exclamation-triangle",
        detail: (
            <p className="errorText" data-cy="report-request-access-disabled-error-message">
                <Trans i18nKey="toasts.reportRequestAccessDisabled"  t={t} 
                components={[
                    <a  key="contact-us"
                        className="contactUsText" 
                        target="_blank" 
                        href="https://www.idwalmarine.com/contact-us" 
                        rel="noopener noreferrer"
                    />
                ]} />
            </p>
        )
    }
}
