import "react-toastify/dist/ReactToastify.css";
import "../Inspection.css";
import { VesselInformation } from "../components/VesselInformation";
import { DownloadAndShare } from "../components/DownloadAndShare";
import { Tabs } from "../components/tab-bar/Tabs";
import { ShareReport } from "../components/dialogs/share-report";
import { initializeAnalytics } from "../services/AnalyticsService";
import { UserAnalyticsConfig } from "../types/UserAnalyticsConfig";
import {useTranslation} from "react-i18next";
import {ITabConfig} from "../types/ITabConfig";
import {Summary} from "../components/summary/Summary";
import {Particulars} from "../components/tab-sections/Particulars";
import {NotableItems} from "../components/NotableItems";
import {Grading} from "../components/tab-sections/Grading";
import {Documents} from "../components/tab-sections/Documents";
import {GradeComparison} from "../components/tab-sections/GradeComparison";
import { useQuery } from "react-query";
import DataRequest from "../queries/DataRequest";
import { showRequestErrorToast } from "../services/ErrorService";
import { VesselParticularsResponse } from "../types/responses/VesselParticularsResponse";


const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const paths = window.location.pathname.split("/");

const customerId = params.customerEmail ?? "dev@idwalmarine.com";
const inspectionId = paths[2] ?? "TEST ID";

const userAnalyticsConfig = {
    inspectionId: inspectionId,
    customerId: customerId,
} as UserAnalyticsConfig;

initializeAnalytics(userAnalyticsConfig);

function Inspection() {
    const { t } = useTranslation("locale");

    const { data, isError, isLoading, error } = useQuery<VesselParticularsResponse>(
        ["particulars"],
        () => DataRequest(inspectionId as string, "particulars")
    );

    const renderShareButton = () => {
        if (isLoading) {
            return <></>;
        }
    
        if (isError) {
            console.error(error);
            showRequestErrorToast("toasts.shareButtonNotLoaded");
            return <></>;
        }
    
        if (data) {
            if (data.DisableShareOption === "True"){
                return <></>;
            }
            else {
                return <ShareReport />
            }
        }
    }

    const ReportTabs : ITabConfig[] = [
        {
            title: t("tabs.summary"),
            content: <Summary />
        },
        {
            title: t("tabs.particulars"),
            content: <Particulars />
        },
        {
            title: t("tabs.notable_items"),
            content: <NotableItems />
        },
        {
            title: t("tabs.grading"),
            content: <Grading />
        },
        {
            title: t("tabs.documents"),
            content: <Documents />
        },
        {
            title: t("tabs.grade_comparison"),
            content: <GradeComparison />
        }
    ];

    return (
        <div className="max-width-wrapper">
            <div className="report-separator" />
            <VesselInformation />
            <div className="relative mt-6">
                <div className="z-1 flex xl:absolute md:right-0 md:top-0 justify-content-center">
                    <DownloadAndShare />
                    {renderShareButton()}
                </div>
                <div className="mt-3 z-0">
                    <Tabs tabs={ReportTabs}></Tabs>
                </div>
            </div>
        </div>
    );
}

export default Inspection;
