import axios from "axios";
const api = `https://${process.env.REACT_APP_USER_INTERACTIONS_ID}.execute-api.eu-west-2.amazonaws.com/${process.env.REACT_APP_ASIMS_ENV}`;
const apiKey = process.env.REACT_APP_USER_INTERACTIONS_APIKEY;

export default axios.create({
    baseURL: api + "/",
    headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey ?? ""
    }
});
