import { ReactElement } from "react";
import { Skeleton } from "primereact/skeleton";

export const LoadingShipImage = (): ReactElement => {
    const loadingShipImage = (
        <div className="flex">
            <div style={{ flex: "1" }}>
                <Skeleton width="100%" height="475px"></Skeleton>
            </div>
        </div>
    );

    return (
        <div>
            <div className="field col-12 md:col-12">
                <div className="custom-skeleton p-2">
                    <ul className="m-0 p-0">{loadingShipImage}</ul>
                </div>
            </div>
        </div>
    );
};
