import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Messages, MessagesMessage } from "primereact/messages";
import { Card } from "primereact/card";
import "../Auth.css";
import { Trans, useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import RequestAccess from "../components/messages/RequestAccess";
import { AccessRequestParams } from "../types/RequestData";
import { useSearchParams } from "react-router-dom";
import ValidateEmail from "../components/messages/ValidateEmail";
import InvalidEmail from "../components/messages/InvalidEmail";
import { createAccessRequest } from "../services/RequestService";
import RequestSent from "../components/messages/RequestSent";
import AccessRequestError from "../components/messages/AccessRequestError";
import { getSpecialCharacters } from "@idwal/idwal-utilities/lib/helpers";
import { LandingDetails } from "../types/LandingDetails";
import DataRequest from "../queries/DataRequest";
import ReportRequestAccessDisabled from "../components/messages/ReportRequestAccessDisabled";
import { errorToast } from "@idwal/idwal-react-components";
import * as EmailValidator from "email-validator";

const VALIDATE_EMAIL_MESSAGE = 1;
const INVALID_EMAIL = 2;
const REQUEST_SENT = 3;
const ACCESS_REQUEST_FAIL = 4;
const ACCESS_REQUEST_INFO = 5;
const REPORT_REQUEST_ACCESS_DISABLED = 6;

function getRequestData(searchParams: URLSearchParams): AccessRequestParams {
    const email = searchParams.get("email");

    return {
        email: email ?? "",
        name: "",
        organisation: ""
    };
}

export default function RequestReportAccess() {
    const { t } = useTranslation("locale");
    const paths = window.location.pathname.split("/");
    const inspectionId = paths[2];
    const [landingDetails, setLandingDetails] = useState<LandingDetails | null>(
        null
    );
    const [addNameErrText, setAddNameErrText] = useState("");
    const [addOrgErrText, setAddOrgErrText] = useState("");
    const [addEmailErrText, setAddEmailErrText] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const infoMessages = useRef<Messages>(null);
    const [infoMsg, setInfoMsg] = useState(ACCESS_REQUEST_INFO);
    const [requestCheck, setRequestCheck] = useState(false);
    const [requestData, setRequestData] = useState(getRequestData(searchParams));

    useEffect(() => {
        async function getLandingDetails() {
            try {
                const details = await DataRequest(inspectionId, "landingData", false);
                setLandingDetails(details);
            } catch (error) {
                console.error("Failed to fetch landing details:", error);   
                errorToast(t('toasts.filedToFetchLandingData'));
            }
        }
    
        getLandingDetails();
    }, []);
    
    function isInputInvalid(input: string) {
        return getSpecialCharacters().test(input);
    }

    function clearMessages() {
        if (infoMessages.current !== null) {
            infoMessages.current.clear();
        }
    }

    function showInfoMessage(message: MessagesMessage) {
        if (infoMessages && infoMessages.current) {
            infoMessages.current.show(message);
        }
    }

    const isValidData = (
        name: string,
        organisation: string,
        email: string
    ): boolean => {   
        if (name.length === 0) {
            setAddNameErrText(t("authModal.errorName"));
        } else if (!addNameErrText) {
            setAddNameErrText("");
        }
        
        if (organisation.length === 0) {
            setAddOrgErrText(t("authModal.errorOrg"));
        } else if (!addOrgErrText) {
            setAddOrgErrText("");
        }
        
        const validatedEmail = isEmailValid(email);
        if (!validatedEmail) {
            setAddEmailErrText(t("authModal.errorEmail"));
        } else if (!addEmailErrText) {
            setAddEmailErrText("");
        }
       
        return (validatedEmail && name.length > 0 && organisation.length > 0 && !addOrgErrText && !addEmailErrText && !addNameErrText);
    };

    function updateEmail(event: ChangeEvent<HTMLInputElement>) {
        if (isInputInvalid(event.target.value)) {
            setAddEmailErrText(t('validation.invalid_character'));
        } else {
            setAddEmailErrText("");
        }
        setRequestData((prevState) => ({
            ...prevState,
            email: event.target.value
        }));
    }

    function updateName(event: ChangeEvent<HTMLInputElement>) {
        if (isInputInvalid(event.target.value)) {
            setAddNameErrText(t('validation.invalid_character'));
        } else {
            setAddNameErrText("");
        }
        setRequestData((prevState) => ({
            ...prevState,
            name: event.target.value
        }));
    }

    function updateOrg(event: ChangeEvent<HTMLInputElement>) {
        if (isInputInvalid(event.target.value)) {
            setAddOrgErrText(t('validation.invalid_character'));
        } else {
            setAddOrgErrText("");
        }
        setRequestData((prevState) => ({
            ...prevState,
            organisation: event.target.value
        }));
    }

    function isEmailValid(email: string) {
        return EmailValidator.validate(email) && !getSpecialCharacters().test(email);
    }

    async function submitRequestAccess() {
        //restrict buyers access to the report only if the flag is specifically set to false on the inspection
        if (landingDetails?.allowResaleReportRequestAccess === false) {
            setInfoMsg(REPORT_REQUEST_ACCESS_DISABLED);
            return;
        }

        setLoading(true);
        if (
            isValidData(
                requestData.name,
                requestData.organisation,
                requestData.email
            )
        ) {
            // Passes validation, make access request
            const result = await createAccessRequest(
                {
                    email: requestData.email,
                    name: requestData.name,
                    organisation: requestData.organisation
                },
                inspectionId
            );
            if (result.status != 200) {
                setInfoMsg(ACCESS_REQUEST_FAIL);
            } else {
                clearMessages();
                setInfoMsg(REQUEST_SENT);
                setRequestCheck(true);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        switch (infoMsg) {
            case VALIDATE_EMAIL_MESSAGE:
                showInfoMessage(ValidateEmail(t));
                break;
            case INVALID_EMAIL:
                showInfoMessage(InvalidEmail(t));
                break;
            case REQUEST_SENT:
                showInfoMessage(RequestSent(t));
                break;
            case ACCESS_REQUEST_FAIL:
                showInfoMessage(AccessRequestError(t));
                break;
            case ACCESS_REQUEST_INFO:
                showInfoMessage(RequestAccess(t));
                break;
            case REPORT_REQUEST_ACCESS_DISABLED:
                showInfoMessage(ReportRequestAccessDisabled(t));
                break;
        }
    }, [infoMsg]);

    if (!requestCheck) {
        return (
            <div className="flex flex-grow-1">
                <div className="page-container report-access flex flex-grow-1 align-items-center justify-content-center xl:px-0 px-4 py-6">
                    <Card
                        className="access-card"
                        header={
                            <h2 id="mobile-header">{t("authModal.header")}</h2>
                        }
                    >
                        <div className="flex flex-column align-items-center justify-content-between">
                            <Messages
                                className="mb-3 w-full"
                                ref={infoMessages}
                            />
                            <div className="w-full mb-5">
                                <p>
                                    <Trans
                                        i18nKey="authModal.requestAccess"
                                        t={t}
                                    >
                                        Please make sure to enter the details of
                                        the person who needs to access the
                                        report. If you are acting as an agent
                                        please contact us on <a href="mailto:enquiries@idwalmarine.com">
                                        enquiries@idwalmarine.com.</a>
                                    </Trans>
                                </p>
                            </div>

                            <div
                                className={`field formgrid w-full ${
                                    addEmailErrText ? "mb-1" : "mb-3"
                                }`}
                                id="mobile"
                            >
                                <label
                                    htmlFor="customerEmail"
                                    className="col-12 md:col-3"
                                >
                                    {t("authModal.emailAddress")}
                                </label>
                                <InputText
                                    value={requestData.email}
                                    onChange={updateEmail}
                                    id="customerEmail"
                                    name="customerEmail"
                                    className={`col-12 md:col-9 p-3 ${
                                        addEmailErrText ? "p-invalid" : null
                                    }`}
                                />
                                {addEmailErrText ? (
                                    <div className="grid col-12">
                                        <div className="md:col-3"></div>
                                        <div className="md:col-9 col-12">
                                            <small className="p-error block">
                                                {addEmailErrText}
                                            </small>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className={`field formgrid w-full ${
                                    addNameErrText ? "mb-1" : "mb-3"
                                }`}
                                id="mobile"
                            >
                                <label
                                    htmlFor="name"
                                    className="col-12 md:col-3"
                                >
                                    {t("authModal.fullName")}
                                </label>
                                <InputText
                                    value={requestData.name}
                                    onChange={updateName}
                                    id="customerName"
                                    name="customerName"
                                    className={`col-12 md:col-9 p-3 ${
                                        addNameErrText ? "p-invalid" : null
                                    }`}
                                />
                                {addNameErrText ? (
                                    <div className="grid col-12">
                                        <div className="md:col-3"></div>
                                        <div className="md:col-9 col-12">
                                            <small className="p-error block">
                                                {addNameErrText}
                                            </small>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className={`field formgrid w-full ${
                                    addOrgErrText ? "mb-1" : "mb-3"
                                }`}
                                id="mobile"
                            >
                                <label
                                    htmlFor="organisation"
                                    className="col-12 md:col-3"
                                >
                                    {t("authModal.organisation")}
                                </label>
                                <InputText
                                    value={requestData.organisation}
                                    onChange={updateOrg}
                                    id="organisation"
                                    name="organisation"
                                    className={`col-12 md:col-9 p-3 ${
                                        addOrgErrText ? "p-invalid" : null
                                    }`}
                                />
                                {addOrgErrText ? (
                                    <div className="grid col-12">
                                        <div className="md:col-3"></div>
                                        <div className="md:col-9 col-12">
                                            <small className="p-error">
                                                {addOrgErrText}
                                            </small>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <Button
                                id="accessReportBtn"
                                label={t("authModal.requestAuthorisation")}
                                onClick={submitRequestAccess}
                                loading={loading}
                            ></Button>
                            <div className="w-full mt-5 mb-2">
                                <p className="text-sm">
                                    <Trans
                                        i18nKey="authModal.termsAndConditions"
                                        t={t}
                                    >
                                        N.B. By requesting access you are agreeing to our <a target="_blank" href="https://www.idwalmarine.com/terms-conditions"> Terms & Conditions </a> and agree to share your details with the seller, including analytics data.
                                    </Trans>
                                </p>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    } else {
        return (
            <div className="page-container report-access flex flex-column align-items-center justify-content-center xl:px-0 px-4 py-6 flex-grow-1">
                <Card
                    header={<h2 id="mobile-header">{t("authModal.header")}</h2>}
                >
                    <div className="flex flex-column align-items-center justify-content-between">
                        <Messages className="mb-5 w-full" ref={infoMessages} />
                        <div className="grid w-full mb-5">
                            <p>
                                <Trans i18nKey="authModal.requestSent" t={t}>
                                    The seller will consider your request and respond in due course. If you have not received a response after 48 hours please <a href="https://www.idwalmarine.com/idwal-contact-us-uk"> contact us</a> for assistance.
                                </Trans>
                            </p>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
