import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Messages, MessagesMessage } from "primereact/messages";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import "../Auth.css";
import { useTranslation, Trans } from "react-i18next";
import Cookies from "universal-cookie";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getTempToken } from "../services/TokenService";
import { hasValidtoken, getAccessToken } from "../services/AccessService";
import Inspection from "./Inspection";
import InvalidEmail from "../components/messages/InvalidEmail";
import ExpiredLink from "../components/messages/ExpiredLink";
import AuthenticatedCustomer from "../components/messages/AuthenticatedCustomer";
import UnpaidCustomer from "../components/messages/UnpaidCustomer";
import InvalidWithLink from "../components/messages/InvalidWithLink";
import ValidateEmail from "../components/messages/ValidateEmail";
import { ReportAccessProps } from "../types/ReportAccessProps";
import { LandingDetails } from "../types/LandingDetails";
import { GetAccessTokenApiResponse } from "../types/GetAccessToken";
import { showRequestErrorToast } from "../services/ErrorService";
import { setAccessTokenCookie } from "../helpers/cookieCreator";
import { COOKIE_NAME } from "../constants/Cookie";
import DataRequest from "../queries/DataRequest";
import {
    checkPaymentAllowed,
    triggerPaymentEmail,
    retrieveLicensingDetails
} from "../services/RequestService";
import PaymentEmail from "../components/messages/PaymentEmail";
import { errorToast } from "@idwal/idwal-react-components";
import { getSpecialCharacters } from "@idwal/idwal-utilities/lib/helpers";
import ReportRequestAccessDisabled from "../components/messages/ReportRequestAccessDisabled";
import * as EmailValidator from "email-validator";

const NO_ERROR_MESSAGE = 0;
const UNPAID_ERROR_MESSAGE = 1;
const INVALID_ERROR_MESSAGE = 2;
const VALIDATE_EMAIL_MESSAGE = 3;
const AUTHENTICATED_CUSTOMER_MESSAGE = 4;
const EXPIRED_LINK = 5;
const INVALID_EMAIL = 6;
const PAYMENT_EMAIL = 7;
const REPORT_REQUEST_ACCESS_DISABLED = 8;

function getEmailFromParams(searchParams: URLSearchParams): string {
    const email = searchParams.get("customerEmail");
    return email ?? "";
}

export default function ReportAccess(props: Readonly<ReportAccessProps>) {
    const { t } = useTranslation("locale");
    const [landingDetails, setLandingDetails] = useState<LandingDetails | null>(null);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [accessCheck, setAccessCheck] = useState(true);
    const [idwalCookieIsValid, setIdwalCookieIsValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState(NO_ERROR_MESSAGE);
    const [email, setEmail] = useState(getEmailFromParams(searchParams));
    const errorMessages = useRef<Messages>(null);

    const redirectionSection = getRedirectionSection();
    const inspectionId = getInspectionId();

    function getInspectionId() {
        if (!params.inspectionId) {
            console.error("No inspection id.");
            showRequestErrorToast("Vessel Information");
            throw new Error("Inspection id cannot be null");
        }

        return params.inspectionId;
    }

    function getRedirectionSection() {
        const section = searchParams.get("section");
        return section ?? "";
    }

    function clearErrors() {
        if (errorMessages.current !== null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Object is possibly 'null'.
            errorMessages.current.clear();
        }
    }

    function showErrorMessage(message: MessagesMessage) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Object is possibly 'null'.
        errorMessages.current.show(message);
    }

    async function initiatePage() {
        const cookies = new Cookies();
        // Use cookie token if it is valid
        if (cookies.get(COOKIE_NAME)) {
            const validateResult: boolean = await hasValidtoken(
                cookies.get(COOKIE_NAME),
                inspectionId
            );
            if (validateResult) {
                setIdwalCookieIsValid(true);
                return;
            } else {
                cookies.remove(COOKIE_NAME, {
                    path: "/",
                    domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN
                });
            }
        }

        const tempToken = searchParams.get("token");
        if (tempToken) {
            try {
                const accessToken = await getAccessToken({
                    data: {
                        inspectionId: inspectionId,
                        customerEmail: email,
                        tempToken: tempToken
                    }
                });

                if (
                    accessToken.status === 200 &&
                    accessToken.data.token != null
                ) {
                    localStorage.setItem("accessToken", accessToken.data.token);
                    setAccessTokenCookie(accessToken.data.token, COOKIE_NAME);
                    setIdwalCookieIsValid(true);
                    return;
                }
            } catch (e: unknown) {
                setAccessCheck(false);
                setErrorMsg(EXPIRED_LINK);
                return;
            }
        } else {
            setAccessCheck(false);
        }
    }

    function isEmailValid() {
        return EmailValidator.validate(email) && !getSpecialCharacters().test(email);
    }

    const navigate = useNavigate();

    async function check(inspectionId: string, email: string) {
        checkPaymentAllowed(inspectionId, email)
            .then((data) => {
                if (data) {
                    if (data.message.allow) {
                        getLicensingDetails(inspectionId, email);
                    } else {
                        setAccessCheck(false);
                        triggerPaymentEmail(inspectionId, email);
                        setErrorMsg(PAYMENT_EMAIL);
                    }
                }
            })
            .catch(() => {
                errorToast(t("payments.check_error"));
            });
    }

    async function getLicensingDetails(inspectionId: string, email: string) {
        retrieveLicensingDetails(inspectionId, email)
            .then((data) => {
                /*
                    customerEmail is build upon right manager response and
                    is only used in "Report licensing details" component 
                    to display the org domain (@example.com) for customer with domain access 
                    or customer email for customers with public emails (no org set e.g. user@gmail.com).
                */
                const customerEmail = data.data.email.indexOf('@') != -1 ? data.data.email : '@' + data.data.email;
                
                if (data) {
                    navigate(
                        `/inspections/${inspectionId}/access/payment`,
                        { state: { email: email, name: data.data.name, organisation: data.data.organisation, organisationEmail: customerEmail } }
                    );
                }
            })
            .catch((err) => {
                console.error(err);
                errorToast(t("payments.check_error"));
            });
    }

    const checkReportRequestAccess = () => {
        //restrict buyers access to the report only if the flag is specifically set to false on the inspection
        if (landingDetails?.allowResaleReportRequestAccess || landingDetails?.allowResaleReportRequestAccess == undefined) {
            navigate(`/inspections/${inspectionId}/access/request`);
        } else {
            setErrorMsg(REPORT_REQUEST_ACCESS_DISABLED)
        }
    }

    async function submitAccessReport() {
        try {
            clearErrors();
            setAccessCheck(true);

            if (!email.length) {
                setErrorMsg(VALIDATE_EMAIL_MESSAGE);
                setAccessCheck(false);
            } else if (isEmailValid() && landingDetails != null) {
                const result = await getTempToken({
                    data: {
                        inspectionId: inspectionId,
                        email: email,
                        shipName: landingDetails.shipName,
                        organisation: landingDetails.organization,
                        customerName: landingDetails.customerName,
                        inspectionReference: landingDetails.inspectionNumber,
                        redirectionSection: redirectionSection,
                        redirectOrigin: window.location.origin
                    }
                });
                
                if (result.data.token) {
                    setAccessCheck(false);
                    setErrorMsg(AUTHENTICATED_CUSTOMER_MESSAGE);
                } else {
                    setAccessCheck(false);
                    setAccessCheck(false);
                    checkReportRequestAccess();
                }
            } else {
                setAccessCheck(false);
                setErrorMsg(INVALID_EMAIL);
            }
        } catch (err: unknown) {
            const error = err as GetAccessTokenApiResponse;            
            if (error.status === 400 || error.status === 422) {
                setErrorMsg(INVALID_ERROR_MESSAGE);
            }
            if (error.status === 401) {
                console.log("NOPE - YOU'RE BLOCKED");
                setErrorMsg(INVALID_ERROR_MESSAGE);
            }
            if (error.status === 402) {
                if (
                    process.env.REACT_APP_PAYMENT_ENABLED &&
                    process.env.REACT_APP_PAYMENT_ENABLED == "1"
                ) {
                    console.info("Payments are enabled.");
                    check(inspectionId, email);
                    return;
                } else {
                    setErrorMsg(UNPAID_ERROR_MESSAGE);
                }
            }
            if (error.status === 403) {
                setAccessCheck(false);
                checkReportRequestAccess();
            }

            setAccessCheck(false);
        }
    }

    useEffect(() => {
        async function getLandingDetails() {
            try {
                const details = await DataRequest(inspectionId, "landingData", false);
                setLandingDetails(details);
            } catch (error) {
                console.error("Failed to fetch landing details:", error);   
                errorToast(t('toasts.filedToFetchLandingData'));
            }
        }

        getLandingDetails();

        initiatePage();
    }, []);

    useEffect(() => {
        props.onAuthenticationChange(idwalCookieIsValid);
    }, [idwalCookieIsValid]);

    useEffect(() => {
        switch (errorMsg) {
            case UNPAID_ERROR_MESSAGE:
                showErrorMessage(
                    UnpaidCustomer(
                        t,
                        landingDetails != null ? landingDetails.shipName : ""
                    )
                );
                break;
            case INVALID_ERROR_MESSAGE:
                showErrorMessage(InvalidWithLink(t));
                break;
            case VALIDATE_EMAIL_MESSAGE:
                showErrorMessage(ValidateEmail(t));
                break;
            case AUTHENTICATED_CUSTOMER_MESSAGE:
                showErrorMessage(AuthenticatedCustomer(t));
                break;
            case EXPIRED_LINK:
                showErrorMessage(ExpiredLink(t));
                break;
            case INVALID_EMAIL:
                showErrorMessage(InvalidEmail(t));
                break;
            case PAYMENT_EMAIL:
                showErrorMessage(PaymentEmail(t));
                break;
            case REPORT_REQUEST_ACCESS_DISABLED:
                showErrorMessage(ReportRequestAccessDisabled(t));
                break;
            default:
                clearErrors();
        }
    }, [errorMsg]);

    if (idwalCookieIsValid && landingDetails != null) {
        return (
            <div className="flex-grow-1">
                <Inspection />
            </div>
        );
    }

    if (!accessCheck && landingDetails != null) {
        const shipName = landingDetails.shipName;
        return (
            <div className="flex flex-grow-1">
                <div className="page-container report-access flex flex-grow-1 align-items-center justify-content-center xl:px-0 px-4 py-6">
                    <Card
                        className="access-card"
                        header={<h2>{t("authModal.header")}</h2>}
                    >
                        <div className="flex flex-column align-items-center justify-content-between">
                            <p className="mb-3 text-center">
                                <Trans
                                    i18nKey="authModal.accessInstruction"
                                    t={t}
                                >
                                    To access the pre-sale report for the{" "}
                                    {{ shipName }} please enter your email
                                    below.
                                </Trans>
                            </p>
                            <Messages className="mb-3" ref={errorMessages} />

                            <div className="field formgrid w-full mb-4">
                                <label
                                    htmlFor="customerEmail"
                                    className="col-12 md:col-4 -ml-2 mr-2"
                                >
                                    {t("authModal.emailAddress")}
                                </label>
                                <InputText
                                    value={email}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                    ) => setEmail(event.target.value.trim())}
                                    id="customerEmail"
                                    name="customerEmail"
                                    className="col-12 md:col-8 p-3"
                                />
                            </div>

                            <Button
                                className="mt-2"
                                id="accessReportBtn"
                                label={t("authModal.accessReport")}
                                loading={accessCheck}
                                onClick={submitAccessReport}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        );
    } else {
        return (
            <div className="auth-wrapper">
                <ProgressSpinner
                    id="progress-spinner"
                    strokeWidth="4"
                    animationDuration="2s"
                />
                <p className="auth-text">{t("authModal.loading")}</p>
            </div>
        );
    }
}
