import {ReactElement} from "react";
import {Loading} from "../loading/Loading"
import {SummaryData} from "../../types/SummaryData";
import {useQuery} from 'react-query'
import {showRequestErrorToast} from "../../services/ErrorService";
import "./Summary.css";
import DataRequest from '../../queries/DataRequest'
import { Summary as ReportSummary } from "@idwal/idwal-react-components";


export const Summary = (): ReactElement => {
    const pathname = window.location.pathname;
    const pathContent = pathname.split("/");
    const inspectionId = pathContent[2];

    if (!inspectionId) {
        showRequestErrorToast('summary (id)');
    }

    const {error, data} = useQuery<SummaryData>(['summary'],
        () => DataRequest(inspectionId, "summary"))

    if (error) {
        showRequestErrorToast("Summary");
        return <div></div>
    }

    if (data) {
        return <ReportSummary summaryData={data} showDecarbonisation={true} />
    }

    return <Loading/>
}
