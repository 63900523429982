export const GA_BUTTON_EVENT = "button_click";
export const GA_PDF_DOWNLOAD = "PDF_Download";
export const GA_DOCS_AND_IMAGES_DOWNLOAD = "docs_images_download";
export const GA_TECHNICAL_REPORT_DOWNLOAD = "Part_B_download";
export const GA_SHARE_ICON = "share_icon";
export const GA_SHARE_CLIPBOARD = "share_clipboard";
export const GA_TAB_SUMMARY = "tab_summary";
export const GA_TAB_PARTICULARS = "tab_particulars";
export const GA_TAB_ITEMS = "tab_NI";
export const GA_TAB_GRADING = "tab_grading";
export const GA_TAB_DOCUMENTS = "tab_documents";
export const GA_IMAGE_CAROUSEL = "image_carousel_click";
export const GA_DOCUMENTS_DOWNLOAD_SELECTED = "docs_download_selected";
export const GA_NI_IMAGE = "ni_image";
export const GA_ACCORDION_TAB = "grading_accordion";
export const GA_TAB_GRADE_COMPARISON = "tab_grade_comparison";
export const GA_SUBMIT_INVOICE_DETAILS_FORM = "submit_invoice_details_form";
export const GA_PAY_NOW = "pay_now_button";
export const GA_BACS_SUBMIT = "bacs_submit_button";
export const GA_VIEW_REPORT = "view_report_button";
export const GA_CREDIT_CARD_TOGGLE = "credit_card_toggle";
export const GA_BACS_TOGGLE = "bacs_toggle";
