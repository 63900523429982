import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { useContext } from "react";
import { PaymentContext } from "./PaymentContext";
import { Trans, useTranslation } from "react-i18next";

/**
 * Bacs success page
 * @returns
 */

export const BacsPaymentSuccess = () => {
    const paymentContext = useContext(PaymentContext);
    const { t } = useTranslation("locale");
    const invoiceEmail = paymentContext.data.invoiceDetails.email;
    
    return (
        <Card title={t("payments.BACS_payment_success.card_title")} data-cy="bacs-success-card">
            <div>
                <p>
                    <Trans i18nKey="payments.BACS_payment_success.header" t={t}>
                        {{invoiceEmail}} 
                    </Trans>
                </p>
                
                <h3 className="mt-3">{t("payments.BACS_payment_success.instructions.title")}</h3>
                <ul>
                    <li className="mt-3">{t("payments.BACS_payment_success.instructions.step_one")}</li>
                    <li className="mt-3">{t("payments.BACS_payment_success.instructions.step_two")}</li>
                    <li className="mt-3">{t("payments.BACS_payment_success.instructions.step_three")}</li>
                    <li className="mt-3">{t("payments.BACS_payment_success.instructions.step_four")}</li>
                </ul>
                <p className="mt-5">
                    <Trans i18nKey="payments.BACS_payment_success.request_help" t={t}>
                        content_str
                        <a 
                            data-cy="contact_btn"
                            className="contactUsText"
                            href="https://www.idwalmarine.com/contact-us"
                            target="_blank"
                        >
                            link_name
                        </a>
                    </Trans>
                </p>
            </div>
        </Card>
    );
};
