import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useTranslation } from "react-i18next";

/**
 * Bacs details
 * @returns
 */
export const BacsPayment = () => {
    const { t } = useTranslation("locale");

    return (
        <div>
            <p>
                <span className="font-semibold">
                    {t("payments.details.bacs_info_response_time")}
                </span>{" "}
                {t("payments.details.bacs_info")}
            </p>
        </div>
    );
};
