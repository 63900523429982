/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { ReactElement, useRef, useEffect } from "react";
import {Trans, useTranslation} from "react-i18next";
import { getGradingComparison } from "../../queries/gradeComparisonQueries";
import { showRequestErrorToast } from "../../services/ErrorService";
import { Loading } from "../loading/Loading";
import { useParams } from 'react-router';
import {Image} from "primereact/image";
import "external-svg-loader";

export const GradeComparison = ():ReactElement => {
    const {t} = useTranslation("locale");
    const { inspectionId } = useParams();
    const comparisonGraph = useRef<SVGSVGElement>(null);
    const scatterGraph = useRef<SVGSVGElement>(null);

    const {data, isError, error, isLoading} = getGradingComparison(inspectionId!);

    useEffect(() => {
        if (comparisonGraph.current) {
            comparisonGraph.current.addEventListener('iconload', () => {
                const title = document.querySelector('#comparisonGraphContainer .gtitle tspan')!.textContent;
                document.querySelector("#compareTitle")!.textContent = title;
            });
        }

        if (scatterGraph.current) {
            scatterGraph.current.addEventListener('iconload', () => {
                const title = document.querySelector('#scatterGraphContainer .gtitle tspan')!.textContent;
                document.querySelector("#scatterTitle")!.textContent = title;
            });
        }
    }, [isLoading]);

    if (isLoading || data == undefined) return <Loading />;

    if (isError) {
        console.error(error);
        showRequestErrorToast("Grading");
        return <div></div>;
    }

    if (!data.ageRangeGraphUrl || !data.ageRangeGraphUrl.length ||
        !data.gradeDistributionGraphUrl || !data.gradeDistributionGraphUrl.length)
    {
        return(
            <div className={"grid"}>
                <div className={"lg:col-offset-2 lg:col-8 col-offset-1 col-10"}>
                    <p>{t("grade_comparison.unavailableExplanation1")}</p>
                    <br />
                    <p>
                        <Trans i18nKey="grade_comparison.unavailableExplanation2" t={t}>
                            If you would like further information on how this vessel compares to others in the market, please
                            <a
                                href="https://www.idwalmarine.com/contact-us"
                                target="_blank"
                            >
                                contact us
                            </a>.
                        </Trans>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={"grid"}>
            <div className={"col-12"}>
                <p>{t("grade_comparison.introduction")}</p>
            </div>
            <div className={"col-12 mb-2"}>
                <h2 className={"border"} id={"compareTitle"}></h2>
            </div>
            <div id={"comparisonGraphContainer"} className={"graph-container col-12 md:col-8 xl:col-9"}>
                <svg
                    ref={comparisonGraph}
                    data-src={data.gradeDistributionGraphUrl}
                    data-cache="disabled"
                    style={{letterSpacing: "inherit", width: "100%", height: "auto", marginTop:"-4.25rem"}}
                />
            </div>
            <div className={"col-12 md:col-4 xl:col-3"}>
                <Image imageClassName={"mw-100"} src={`https://customer.idwalmarine.com/Content/report/2.0.0/images/compare-graph-key.svg`} />
                <p className={"mt-3 legend-text"}>{t("grade_comparison.gradeDistributionExplanation")}</p>
            </div>
            <div className={"col-12 mb-2"}>
                <h2 className={"border"} id={"scatterTitle"}></h2>
            </div>
            <div id={"scatterGraphContainer"} className={"graph-container col-12 md:col-8 xl:col-9"}>
                <svg
                    ref={scatterGraph}
                    data-src={data.ageRangeGraphUrl}
                    data-cache="disabled"
                    style={{letterSpacing: "inherit", width: "100%", height: "auto", marginTop:"-4.25rem"}}
                />
            </div>
            <div className={"col-12 md:col-4 xl:col-3"}>
                <Image imageClassName={"mw-100"} src={"https://customer.idwalmarine.com/Content/report/2.0.0/images/compare-scatter-key.svg"} />
                <p className={"mt-3 legend-text"}>{t("grade_comparison.ageRangeExplanation")}</p>
            </div>
            <div className={"col-12"}>
                <p className={"paragraph-max-width"}>{t("grade_comparison.footnote1")}</p>
                <br />
                <p className={"paragraph-max-width"}>{t("grade_comparison.footnote2")}</p>
            </div>
        </div>
    );
};