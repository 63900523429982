import React from "react";
import { TFunction, Trans } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function RequestAccess(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "info",
        closable: false,
        content: (
            <React.Fragment>
                <i
                    className="pi pi-info-circle info-icon mr-2"
                    style={{ color: "#00C7CC" }}
                />
                <p className="errorText">
                    <Trans i18nKey="toasts.requestAccess" t={t}>
                        Access to purchase this report requires seller
                        authorisation and will incur
                        <br /> a fee once approved
                    </Trans>
                </p>
            </React.Fragment>
        )
    };
}
