/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import "@idwal/idwal-react-components/css/idwal-theme.css";
import { sendGAEvent } from "../../services/AnalyticsService";
import {GA_BUTTON_EVENT} from "../../constants/GaEvents";
import { ShareReport as ShareReportMenu } from "@idwal/idwal-react-components";

export const ShareReport = ():ReactElement => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete("token");
    urlSearchParams.delete("customerEmail");
    urlSearchParams.delete("customerName");
    urlSearchParams.delete("organisation");

    let shareLink = `${window.location.origin}`;
    shareLink += window.location.pathname.replace("seller","access");
    
    const handleAnalyicsEvent = (action: string) => {
        sendGAEvent(GA_BUTTON_EVENT, action);
    }

    return (
        <ShareReportMenu sendAnalyticsEvent={handleAnalyicsEvent} shareLink={shareLink} />
    );
}
