import { AccessTokenResponse } from "../types/AccessTokenResponse";
import UserInteractionAxios from "../user-interactions-server/api";
import { hasAuthorizedAccess, hasValidtoken } from "./AccessService";
import {
    GetTempTokenApiResponse,
    GetTempTokenRequest
} from "../types/GetTempToken";

export async function validateAccess(
    inspectionId: string,
    customerEmail: string
) {
    const access = await getAccessToken(inspectionId, customerEmail);

    if (access?.token) {
        const validateResult: boolean = await hasValidtoken(
            access.token,
            inspectionId
        );
        console.info(validateResult);

        if (!validateResult) {
            return { valid: false, token: null, hasPaid: null };
        }

        return { valid: true, token: access.token, hasPaid: access.hasPaid };
    } else {
        if (access?.hasPaid === false) {
            return { valid: false, token: null, hasPaid: false };
        }
    }
}

export async function getAccessToken(
    inspectionId: string,
    customerEmail: string
) {
    const authAccessResult: AccessTokenResponse = await hasAuthorizedAccess(
        inspectionId,
        customerEmail
    );

    if (!authAccessResult) {
        throw new Error("Authorization response was unexpected");
    }

    try {
        if (!authAccessResult.success) {
            if (!authAccessResult.hasPaid) {
                return { hasPaid: false };
            }
        } else {
            if (authAccessResult.accessToken) {
                return { token: authAccessResult.accessToken };
            }
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getTempToken(getTempTokenRequest: GetTempTokenRequest) {
    return new Promise<GetTempTokenApiResponse>((res, rej) => {
        UserInteractionAxios.post("temp-token", getTempTokenRequest)
            .then((response) => {
                res(response);
            })
            .catch((e) => {
                rej(e.response as GetTempTokenApiResponse);
            });
    });
}

/**
 * Check if user has paid
 * @param inspectionId
 * @param email
 * @returns
 */
export async function checkPaidAccess(
    inspectionId: string,
    email: string
): Promise<string | null> {
    const authAccessResult: AccessTokenResponse = await hasAuthorizedAccess(
        inspectionId,
        email
    );

    if (authAccessResult && authAccessResult.accessToken) {
        return authAccessResult.accessToken;
    }
    return null;
}
