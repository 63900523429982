import {TFunction, Trans} from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function InvalidEmail(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "error",
        closable: false,
        detail: (
            <p className="errorText">
                <Trans i18nKey="toasts.invalidEmail" t={t}>
                    Please enter a valid e-mail address and try again. Please
                    <a
                        className="contactUsText"
                        href="https://www.idwalmarine.com/contact-us"
                        target="_blank"
                    >
                        contact us
                    </a>
                    if you continue to have problems accessing this report.
                </Trans>
            </p>
        )
    }
}
