const downloadFile = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export default downloadFile;