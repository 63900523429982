import Cookies from "universal-cookie";
import { HOURS_TO_EXPIRY } from "../constants/Cookie";

export function setAccessTokenCookie(accessToken: string, cookieName: string) {
    const cookies = new Cookies();
    const expiry = new Date();
    expiry.setHours(expiry.getHours() + HOURS_TO_EXPIRY);
    cookies.set(cookieName, accessToken, {
        path: "/",
        domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
        expires: expiry
    });
}
