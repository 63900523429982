import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { PaymentStages } from "../../types/PaymentStages";
import { useContext } from "react";
import { PaymentContext } from "./PaymentContext";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

/**
 * Goes back to the start of the payment flow (invoicing)
 * If this flow gets expanded, perhaps pass a flow stage into this component to go back to?
 * @returns
 */
export const PaymentBack = () => {
    const { t } = useTranslation("locale");
    const paymentContext = useContext(PaymentContext);

    const pressBack = () => {
        paymentContext.update(
            PaymentStages.INVOICE_DETAILS,
            paymentContext.data
        );
    };

    return (
        <div className="lg:block xl:block w-full flex justify-content-center ">
            <Button
                onClick={pressBack}
                data-cy="payment-back"
                label={t("payments.details.back")}
                className="p-button-text p-button-sm"
                icon="pi pi-angle-double-left"
            />
        </div>
    );
};
