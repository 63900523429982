import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import {ProgressSpinner} from 'primereact/progressspinner';
import Inspection from './Inspection'

type Props = {
    idwalCookieIsValid: boolean;
};

export default function Seller(props: Props) {

    if (props.idwalCookieIsValid) {
        return (
            <div className="flex-grow-1">
                <Inspection/>
            </div>
        )
    }
    return (
        <div className="auth-wrapper">
            <ProgressSpinner id="progress-spinner"
                             strokeWidth="4"
                             animationDuration="2s"/>
            <p className="auth-text">authenticating...</p>
        </div>
    )
}
