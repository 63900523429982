import {
    AccessRequestDataApiResponse,
    AccessRequestParams
} from "../types/RequestData";
import { CheckPaymentAccessResponse } from "../types/responses/CheckPaymentAccessResponse";
import {LicensingDetailsResponse} from "../types/responses/LicensingDetailsResponse";
import RequestDataAxios from "../idwal-api/api";

export async function createAccessRequest(
    getRequestService: AccessRequestParams,
    inspectionId: string
) {
    return new Promise<AccessRequestDataApiResponse>((res) => {
        RequestDataAxios.post(
            `/Inspections/${inspectionId}/RequestAccess`,
            getRequestService
        )
            .then((response) => {
                res(response);
            })
            .catch((e) => {
                res(e.response.data as AccessRequestDataApiResponse);
            });
    });
}

export async function checkPaymentAllowed(inspectionId: string, email: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((res) => {
        RequestDataAxios.get(
            `/Buyer/inspection/payment-allowed/${inspectionId}/email/${email}`
        )
            .then((response) => {
                res(response.data);
            })
            .catch((e) => {
                res(e.response as CheckPaymentAccessResponse);
            });
    });
}

export async function triggerPaymentEmail(inspectionId: string, email: string) {
    const data = {
        inspectionId: inspectionId,
        email: email,
        type: 36
    };

    return new Promise<CheckPaymentAccessResponse>((res) => {
        RequestDataAxios.post(`/Inspections/${inspectionId}/Notification`, data)
            .then((response) => {
                res(response);
            })
            .catch((e) => {
                res(e.response as CheckPaymentAccessResponse);
            });
    });
}

export async function retrieveLicensingDetails(inspectionId: string, email: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((res) => {
        RequestDataAxios.get(
            `/Buyer/inspection/get-licensing/${inspectionId}/email/${email}`
        )
            .then((response) => {
                res(response.data as LicensingDetailsResponse);
            })
            .catch((e) => {
                res(e.response as LicensingDetailsResponse);
            });
    });
}
