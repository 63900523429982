import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { PaymentBack } from "./PaymentBack";
import { useContext, useEffect, useState } from "react";
import { PaymentContext } from "./PaymentContext";
import { PaymentMethod } from "../../types/PaymentMethod";
import {
    sendInvoiceNotification,
    startPayment
} from "../../services/PaymentService";

import { PaymentStages } from "../../types/PaymentStages";
import { checkPaidAccess } from "../../services/TokenService";
import { useLocation } from "react-router-dom";
import { setAccessTokenCookie } from "../../helpers/cookieCreator";
import { COOKIE_NAME } from "../../constants/Cookie";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
    GA_BACS_SUBMIT,
    GA_BUTTON_EVENT,
    GA_PAY_NOW
} from "../../constants/GaEvents";
import ReactGA from "react-ga4";

const PAYMENT_CHECK_INTERVAL = 5000;
const MAX_ATTEMPTS = 10;

let PAYMENT_LISTEN_ATTEMPTS = 0;

/**
 * Footer for payment flow, contains back button and pay/bacs submit button
 * @returns
 */
export const PaymentFooter = () => {
    const { t } = useTranslation("locale");
    const paymentContext = useContext(PaymentContext);
    const location = useLocation();

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);
    const [cardPaymentInProcess, setCardPaymentInProcess] =
        useState<boolean>(false);

    useEffect(() => {
        if (stripe) {
            setLoading(false);
        }
    }, [stripe]);

    /**
     * Submits payment form
     * @param event
     * @returns
     */
    async function submit(event: { preventDefault: () => void }) {
        setLoading(true);
        if (paymentContext.paymentMethod === PaymentMethod.CARD) {
            event.preventDefault();
            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }
            const result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: ""
                },
                redirect: "if_required"
            });

            if (result.error) {
                paymentContext.update(paymentContext.stage, {
                    invoiceDetails: paymentContext.data.invoiceDetails,
                    resaleCostDetails: paymentContext.data.resaleCostDetails,
                    paymentDetails: paymentContext.data.paymentDetails,
                    paymentErrorMessage: result.error.message
                });
                setLoading(false);
            } else {
                setCardPaymentInProcess(true);
                listenForPaymentSuccess();
                sendInvoiceNotification(
                    paymentContext.data.resaleCostDetails.inspectionId,
                    location.state,
                    paymentContext.paymentMethod,
                    paymentContext.data.invoiceDetails,
                    paymentContext.data.resaleCostDetails,
                    paymentContext.data.paymentDetails.invoiceRef
                );
                ReactGA.event({
                    action: GA_PAY_NOW,
                    category: GA_BUTTON_EVENT,
                    label: "Pay Now"
                });
            }
        }

        if (paymentContext.paymentMethod === PaymentMethod.BACS) {
            try {
                const data = await startPayment(
                    paymentContext.data.resaleCostDetails.inspectionId,
                    location.state.email,
                    paymentContext
                );

                paymentContext.data.paymentDetails.invoiceRef =
                    data?.invoiceRef || "";

                paymentContext.update(
                    PaymentStages.BACS_PAYMENT_COMPLETE,
                    paymentContext.data
                );

                sendInvoiceNotification(
                    paymentContext.data.resaleCostDetails.inspectionId,
                    location.state,
                    paymentContext.paymentMethod,
                    paymentContext.data.invoiceDetails,
                    paymentContext.data.resaleCostDetails,
                    paymentContext.data.paymentDetails.invoiceRef
                );

                ReactGA.event({
                    action: GA_BACS_SUBMIT,
                    category: GA_BUTTON_EVENT,
                    label: "Generate Invoice"
                });
            } catch (err) {
                console.error(err);
            }
        }
    }

    /**
     * Starts a payment listener that runs every x seconds
     */
    useEffect(() => {
        if (cardPaymentInProcess) {
            const recurring = setInterval(
                () => listenForPaymentSuccess(),
                PAYMENT_CHECK_INTERVAL
            );
            return () => {
                // Vital, will cancel once component derenders.
                clearInterval(recurring);
            };
        }
    }, [cardPaymentInProcess]);

    /**
     * Check for valid token
     */
    function listenForPaymentSuccess() {
        if (PAYMENT_LISTEN_ATTEMPTS === MAX_ATTEMPTS) {
            paymentContext.update(PaymentStages.PAYMENT_FAILURE, {
                ...paymentContext.data,
                paymentDetails: {
                    ...paymentContext.data.paymentDetails,
                    error: "Error"
                }
            });
            return;
        }

        checkPaidAccess(
            paymentContext.data.resaleCostDetails.inspectionId,
            location.state.email
        ).then((token) => {
            if (token) {
                localStorage.setItem("accessToken", token);
                setAccessTokenCookie(token, COOKIE_NAME);

                paymentContext.update(
                    PaymentStages.CARD_PAYMENT_COMPLETE,
                    paymentContext.data
                );
            }
        });

        PAYMENT_LISTEN_ATTEMPTS++;
    }

    return (
        <>
            <div className="flex w-full mt-5 justify-content-center mt-2">
                <Button
                    className="lg:w-auto w-full"
                    data-cy="payment-submit-button"
                    label={
                        paymentContext.paymentMethod === PaymentMethod.CARD
                            ? t("payments.details.pay_now")
                            : t("payments.details.bacs_submit")
                    }
                    loading={loading}
                    onClick={submit}
                />
            </div>

            <div className="lg:-mt-4 mt-4">
                <PaymentBack />
            </div>
        </>
    );
};
