import { useTranslation } from "react-i18next";
import React, { ReactElement, useEffect, useState } from "react";
import { successToast, warningToast, errorToast, infoToast, DownloadMenu } from "@idwal/idwal-react-components";
import { sendGAEvent } from "../services/AnalyticsService";
import { getDownloads } from "../queries/downloadQueries";
import { useParams } from "react-router";
import { DocumentData } from "../types/DocumentData";
import { FULL_REPORT_DOC_NAME, REPORT_ZIP_NAME, TECHNICAL_REPORT_NAME } from "../constants/inspectionElementNames";
import {GA_BUTTON_EVENT, GA_DOCS_AND_IMAGES_DOWNLOAD, GA_PDF_DOWNLOAD, GA_TECHNICAL_REPORT_DOWNLOAD} from "../constants/GaEvents";
import downloadFile from "../queries/clientDownloader";
import AuthToken from '../queries/AuthToken'
import {Claims} from "../types/Claims";
import { DocumentResponse } from "../types/responses/documentResponse";
import IdwalAdobeViewSDKClient from "@idwal/idwal-utilities/lib/IdwalAdobeViewSDKClient/IdwalAdobeViewSDKClient";
import {AppContext} from "../App";
import {AppContextType} from "../types/AppContextType";


export const DownloadAndShare = (): ReactElement => {
    const { t } = useTranslation("locale");
    const [downloadOption, setDownloadOption] = useState("");
    const { inspectionId } = useParams();
    const [fullReportDoc, setFullReportDoc] = useState<DocumentData | undefined>(undefined);
    const [reportZip, setReportZip] = useState<DocumentData | undefined>(undefined);
    const [technicalReport, setTechnicalReport] = useState<DocumentData | undefined>(undefined);
    const viewSDKClient = new IdwalAdobeViewSDKClient();
    const { setRestrictedView } = React.useContext(AppContext) as AppContextType;


    const { data, refetch } = getDownloads(inspectionId ?? "");

    const downloadKeyMap = [
        { documentKey: FULL_REPORT_DOC_NAME, file: fullReportDoc },
        { documentKey: REPORT_ZIP_NAME, file: reportZip },
        { documentKey: TECHNICAL_REPORT_NAME, file: technicalReport }
    ];

    useEffect(() => {
        if (data) {
            setFullReportDoc(data.fullReport);
            setTechnicalReport(data.technicalReportElement);
            setReportZip(data.zipElement);

            if (data.message) {
                infoToast(data.message);
            }
        }
    },[data]);

    setRestrictedView(data?.limitDownloadsToSupportingDocumentsOnly as boolean);

    const items = (
        data?.limitDownloadsToSupportingDocumentsOnly &&
        process.env.REACT_APP_RESTRICT_DOWNLOAD_OPTIONS_FOR_SELLERS
    ) ? [] : [
        {
            label: "Idwal Report PDF",
            key: FULL_REPORT_DOC_NAME,
        },
        {
            label: "Supporting Documents and Images",
            key: REPORT_ZIP_NAME,
        },
        {
            label: "Technical Report",
            key: TECHNICAL_REPORT_NAME,
        },
    ];

    const token = AuthToken();
    let customerName = "";
    let organisation = "";
    let claims;

    if (token) {
        claims = JSON.parse(atob(token.split('.')[1])) as Claims;
        customerName = claims.name;
        organisation = claims.organisation;
    }
    
    const onAgreeAndDownload = async () => {
        successToast(t("report.downloadStarted"));
        await downloadItem();
    };

    const updateDocuments = (data: DocumentResponse) => {
        setFullReportDoc(data.fullReport);
        setTechnicalReport(data.technicalReportElement);
        setReportZip(data.zipElement);
    }

    useEffect(() => {
        if (data) {
            updateDocuments(data)
        }
    },[data])

    function handleGaEvent(documentKey: string) {
        const actionMap: { [name: string]: string } = {
            [FULL_REPORT_DOC_NAME]: GA_PDF_DOWNLOAD,
            [REPORT_ZIP_NAME]: GA_DOCS_AND_IMAGES_DOWNLOAD,
            [TECHNICAL_REPORT_NAME]: GA_TECHNICAL_REPORT_DOWNLOAD
        }

        const action: string = actionMap[documentKey];

        if (action) {
            sendGAEvent(GA_BUTTON_EVENT, action);
        }
    }

    async function downloadItem() {
        handleGaEvent(downloadOption);
        const documentToDownload = downloadKeyMap.find(e => e.documentKey === downloadOption);

        if (!documentToDownload || !documentToDownload.file) {
            const warnText = t("downloads.noDownload") + downloadOption;
            console.warn(warnText);
            warningToast(warnText);
            return;
        }

        try {
            const response = await fetch(documentToDownload.file.value.url);
            if (!response || response.status !== 200) {
                console.error(response.statusText + " - " + documentToDownload.file.value.url)
                warningToast(t("downloads.downloadFailed"));
                return;
            }

            downloadFile(documentToDownload.file.value.url, documentToDownload.file.value.fileName);

        } catch (error) {
            console.error(error);
            warningToast(t("downloads.downloadFailed"));
        }
    }

    const termsAndConditions = [
        `${t("report.tsAndCs_1", { customerName, organisation })}`,
        `${t("report.tsAndCs_2")}`,
        `${t("report.tsAndCs_3")}`,
        `${t("report.tsAndCs_4")}`,
    ];

    const previewFile = () => {
        refetch().then(
            (response) => {
                if (response.data?.fullReport) {
                    viewSDKClient.ready().then(() => {
                        viewSDKClient.previewFile(
                            process.env.REACT_APP_ADOBE_API_KEY,
                            response.data?.fullReport.value.url,
                            response.data?.fullReport.value.fileName, 
                            response.data?.fullReport.key, 
                            {
                                showAnnotationTools: false, 
                                showDownloadPDF: false, 
                                showPrintPDF: false,
                                embedMode: "LIGHT_BOX"
                            }
                        );
                        viewSDKClient.registerEventsHandler();
                    });
                    updateDocuments(response.data)
                } else {
                    if (response.data?.message) {
                        warningToast(response.data.message);
                    }
                    console.error("Full report not in response");
                }
            }
        ).catch((e) => {
            console.error("Error fetching document: " + e);
            errorToast(t("report.errorMessage"));
        });
    }

    return (
        <DownloadMenu 
            downloads={items}
            onAgreeAndDownload={onAgreeAndDownload}
            setDownloadOption={setDownloadOption}
            onPeview={previewFile}
            modalHeader={t("report.reportSharingTsAndCs")}
            termsAndConditions={termsAndConditions}
            termsAndConditionsLink={
                "https://www.idwalmarine.com/terms-and-conditions/inspection-reports"
            }
            previewMode={
                process.env.REACT_APP_PREVIEW_PDF_ENABLED === "1"
            }
        />
    );
};
