import { TFunction, Trans } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function AccessRequestError(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "error",
        closable: false,
        detail: (
            <p className="errorText">
                <Trans i18nKey="toasts.accessRequestError" t={t}>
                    Failed to submit access request. Please
                    <a
                        className="contactUsText"
                        href="https://www.idwalmarine.com/contact-us"
                        target="_blank"
                    >
                        contact us
                    </a>
                    if you continue to have problems accessing this report.
                </Trans>
            </p>
        )
    };
}
