import React from "react";
import { TFunction, Trans } from "react-i18next";
import { MessagesMessage } from "primereact/messages";

export default function RequestSent(t: TFunction): MessagesMessage {
    return {
        sticky: true,
        severity: "success",
        closable: false,
        content: (
            <React.Fragment>
                <i className="pi pi-check mr-2" style={{ color: "#53A440" }} />
                <p className="errorText">
                    <Trans i18nKey="toasts.requestSent" t={t}>
                        A request for authorisation has been sent to the seller.
                    </Trans>
                </p>
            </React.Fragment>
        )
    };
}
