import { ReactElement, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { showRequestErrorToast } from "../../services/ErrorService";
import { Loading } from "../loading/Loading";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import {successToast, warningToast, errorToast, DocumentsTable} from "@idwal/idwal-react-components";
import JSZip from "jszip";
import {DocumentTableEntry} from "../../types/DocumentData";
import {sendGAEvent} from "../../services/AnalyticsService";
import {GA_BUTTON_EVENT, GA_DOCUMENTS_DOWNLOAD_SELECTED} from "../../constants/GaEvents";
import { REPORT_ZIP_NAME } from "../../constants/inspectionElementNames";
import downloadFile from "../../queries/clientDownloader";
import { toast } from "react-toastify";
import { ProgressBar } from 'primereact/progressbar';
import {getDownloads} from "../../queries/downloadQueries";

const ZIP_EXTENSION = ".zip";


export const Documents = ():ReactElement => {
    const {t} = useTranslation("locale");
    const { inspectionId } = useParams();
    const toastId = useRef<null | string | number>(null);
    const [zipJobInProgress, setZipJobInProgress] = useState(false);

    if (!inspectionId) {
        console.error("Documents - No inspection id.");
        showRequestErrorToast("Documents");
        return <div></div>;
    }

    const {data, isError, isLoading, error} = getDownloads(inspectionId);

    if (isLoading || data == undefined) return <Loading />;

    if (isError) {
        console.error(error);
        showRequestErrorToast("Documents");
        return <div></div>;
    }

    //group data by element
    const tableData : DocumentTableEntry[] = [];
    data.documents.forEach(doc => {
        if (doc.value.elementName == REPORT_ZIP_NAME){
            return;
        }
        const existingEntry = tableData.filter(td => td.elementName == doc.value.elementName)[0];
        if (!existingEntry) {
            tableData.push( { elementName: doc.value.elementName, elements: [ { fileName: doc.value.fileName, url: doc.value.url } ]})
        } else {
            existingEntry.elements.push({ fileName: doc.value.fileName, url: doc.value.url });
        }
    });

    const downloadDocument = async (rowData: DocumentTableEntry) =>
    {
        successToast(t("documents.downloadStarted"));
        sendGAEvent(GA_BUTTON_EVENT, `docs_${rowData.elementName}_download`);
        if (rowData.elements.length == 1) {
            const file = rowData.elements[0];
            const response = await fetch(file.url);
            if (!response || response.status !== 200) {
                console.error(response.statusText + " - " + file.url)
                warningToast(t("downloads.downloadFailed"));
                return;
            }

           downloadFile(file.url, file.fileName);

        } else {
            await downloadZip([rowData], rowData.elementName + ZIP_EXTENSION);
        }
    }

    const downloadSelected = async (selectedDocuments: DocumentTableEntry[]) => {
        if (selectedDocuments.length) {
            const fullZip = data.zipElement;
            if (selectedDocuments.length == 1)
            {
                await downloadDocument(selectedDocuments[0]);
            } else if (selectedDocuments.length == tableData.length && fullZip?.value?.url) {
                //All elements selected - download the full report zip file
                await downloadDocument({ elementName: REPORT_ZIP_NAME, elements: [ { fileName: fullZip.value.fileName, url: fullZip.value.url } ] });

            } else {
                sendGAEvent(GA_BUTTON_EVENT, GA_DOCUMENTS_DOWNLOAD_SELECTED);
                successToast(t("documents.downloadStarted"));
                await downloadZip(selectedDocuments, data.zipFileName);
            }
        } else {
            errorToast(t("documents.noDocumentsSelected"));
        }
    }

    const downloadZip = async (documentsToZip: DocumentTableEntry[], fileName: string) =>
    {
        setZipJobInProgress(true);
        try {
            const zip = new JSZip();
            toastId.current = toast(t("documents.preparingFilesForDownload"),
                {
                    type: "success",
                    closeButton: false,
                    closeOnClick: false,
                    progressClassName: "toast-progress-bar",
                    icon: (
                        <i
                            className="pi pi-check"
                            style={{
                                color: "var(--toastify-icon-color-success)",
                                fontSize: "1em"
                            }}
                        ></i>
                    )});

            const totalFilesToDownload = documentsToZip.flatMap(x => x.elements).length;
            let filesDownloaded = 0;
            for (const document of documentsToZip)
            {
                let folder : JSZip | null = null;

                if (documentsToZip.length > 1) {
                    folder = zip.folder(document.elementName);
                }

                const blobFetcher = await Promise.all(
                    document.elements.map(async (document) => {
                        const res = await fetch(document.url);
                        const blob = res.blob();

                        const progress = ++filesDownloaded/totalFilesToDownload;
                        const progressPercent = Math.round(progress*100);
                        if (toastId.current){
                            toast.update(toastId.current, { progress: progress, render: () =>
                                    <div>
                                        <p>{t("documents.preparingFilesForDownload")}</p>
                                        <ProgressBar className="file-progress" value={progressPercent} displayValueTemplate={() => <span>{`${filesDownloaded}/${totalFilesToDownload}`}</span>}></ProgressBar>
                                    </div> });
                        }

                        return blob;
                    })
                );

                const fileNames: string[] = [];

                blobFetcher.forEach((blob, index) => {
                    const fileName = document.elements[index].fileName;

                    if (!fileNames.includes(fileName)) {
                        (folder ?? zip).file(fileName, blob);
                        fileNames.push(fileName);
                    } else {
                        let adjustIterator = 1;
                        const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf("."))
                        const ext = fileName.substring(fileName.lastIndexOf("."));

                        let adjustedFileName: string;
                        do {
                            adjustedFileName = `${fileNameWithoutExtension}_${adjustIterator++}${ext}`;
                        } while (fileNames.includes(adjustedFileName));
                        (folder ?? zip).file(adjustedFileName, blob);
                        fileNames.push(adjustedFileName);
                    }
                });
            }

            zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, fileName);
            });
        } catch {
            if (toastId.current) {
                toast.done(toastId.current);
            }
            errorToast(t("documents.downloadFailed"));
        } finally {
            setZipJobInProgress(false);
        }
    }

    return (
        <DocumentsTable downloadDocument={downloadDocument} downloadSelectedDocuments={downloadSelected} documents={tableData} jobInProgress={zipJobInProgress} />
    );
};
