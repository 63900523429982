import { useNavigate } from "react-router-dom";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../Auth.css";

import { ReportLicensingDetails } from "../components/payment/ReportLicensingDetails";
import { useContext, useEffect } from "react";
import { getInspectionPaymentDetails } from "../services/PaymentService";
import { Spinner } from "@idwal/idwal-react-components";
import { InvoiceDetailsForm } from "../components/payment/InvoiceDetailsForm";
import { PaymentDetails } from "../components/payment/PaymentDetails";
import { PaymentStages } from "../types/PaymentStages";
import { PaymentContext } from "../components/payment/PaymentContext";
import { BacsPaymentSuccess } from "../components/payment/BACSPaymentSuccess";
import { CardPaymentSuccess } from "../components/payment/CardPaymentSuccess";
import { PaymentFailure } from "../components/payment/PaymentFailure";

export default function Payments() {
    const paths = window.location.pathname.split("/");
    const inspectionId = paths[2];

    const navigate = useNavigate();

    const paymentContext = useContext(PaymentContext);

    useEffect(() => {
        if (document.cookie.indexOf(inspectionId) != -1) {
            navigate(`/inspections/${inspectionId}/access`);
        }

        getInspectionPaymentDetails(inspectionId).then((data) => {
            if (data) {
                data.inspectionId = inspectionId;
                paymentContext.update(paymentContext.stage, {
                    invoiceDetails: paymentContext.data.invoiceDetails,
                    resaleCostDetails: data,
                    paymentDetails: paymentContext.data.paymentDetails,
                    paymentErrorMessage: paymentContext.data.paymentErrorMessage
                });
            }
        });
    }, []);

    if (
        !process.env.REACT_APP_PAYMENT_ENABLED ||
        process.env.REACT_APP_PAYMENT_ENABLED == "0"
    ) {
        navigate(`/inspections/${inspectionId}/access`);
    }

    if (paymentContext.data.resaleCostDetails.resalePrice) {
        return (
            <div className="report-access flex flex-grow-1">
                <div className="max-width-wrapper flex flex-grow-1 py-6">
                    <div className="page-container report-access grid align-items-start justify-content-center flex-grow-1">
                        <div className="col-12 md:col-6">
                            {paymentContext.stage ===
                                PaymentStages.INVOICE_DETAILS && (
                                <InvoiceDetailsForm />
                            )}
                            {paymentContext.stage ===
                                PaymentStages.PAYMENT_DETAILS && (
                                <PaymentDetails />
                            )}
                            {paymentContext.stage ===
                                PaymentStages.CARD_PAYMENT_COMPLETE && (
                                <CardPaymentSuccess />
                            )}
                            {paymentContext.stage ===
                                PaymentStages.BACS_PAYMENT_COMPLETE && (
                                <BacsPaymentSuccess />
                            )}
                            {paymentContext.stage ===
                                PaymentStages.PAYMENT_FAILURE && (
                                <PaymentFailure />
                            )}
                        </div>
                        <div className="col-12 md:col-6">
                            <ReportLicensingDetails />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        if (
            paymentContext.data.resaleCostDetails.inspectionId &&
            !paymentContext.data.resaleCostDetails.resalePrice
        ) {
            return (
                <div className="page-container report-access flex flex-column align-items-center justify-content-center flex-grow-1">
                    <h3>Sorry, this report is not ready to be purchased</h3>
                    <br />
                    <p>Please try again later, or get in contact with us</p>
                </div>
            );
        }
        return (
            <div className="page-container report-access flex align-items-center justify-content-center flex-grow-1">
                <Spinner width={50} height={50} />
            </div>
        );
    }
}
