import {ReactElement} from "react";
import {Skeleton} from 'primereact/skeleton';

export const Loading = (): ReactElement => {

    const loadingLine = <div className="mb-5">
        <div className="flex">
            <div style={{flex: '1'}}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
            </div>
        </div>
    </div>;

    return <div>
        <div className="field col-12 md:col-12">
            <div className="custom-skeleton p-2">
                <ul className="m-0 p-0">
                    {loadingLine}
                    {loadingLine}
                    {loadingLine}
                    {loadingLine}
                    {loadingLine}
                </ul>
            </div>
        </div>
    </div>
};
