import CookieConsent from "react-cookie-consent";
import React from "react";
import {useTranslation} from "react-i18next";
import "./ConsentCookie.css";

 
export function ConsentCookie() {
    const { t } = useTranslation("locale");

    return (
        <CookieConsent
            location="bottom"
            buttonText={t("cookieConsent.understand")}
            cookieName="accessPortalCookie"
            style={{
                lineHeight: "24px",
                fontSize: "1rem",
                color: "#FFFFFF",
                padding: "8px 0px",
                background: "#28235B",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                textAlign: "center",
                fontWeight: "400",
            }}
            buttonStyle={{
                width: "113px",
                height: "33px",
                background: "#00C7CC",
                border: "1px solid var(--idwal-teal)",
                borderRadius: "4px",
                margin: "11.5px 48px 11.5px 10px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "600",
                lineHeight: "150%",
                letterSpacing: "0.5px",
                color: "#FFFFFF"
                
            }}
            containerClasses="cookie-mobile-container"
            contentClasses="cookie-mobile-content"
            buttonClasses="cookie-mobile-button"
        >
            {t("cookieConsent.info")}
        </CookieConsent>
    );
}
